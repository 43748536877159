import { useEffect } from "react";
import { Img_UsecaseBlog } from "../../config/constants";
import ReactGA from "react-ga";

const Post2 = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname, "", "Blockchain Usecase Blog");
  }, [])

  return (
    <div className="blogs-container">
      <div className="blog-img-container mt-4">
        <img className="blg-img" src={Img_UsecaseBlog} alt="Img_UsecaseBlog" />
      </div>
      <div className="blog-contents txt-blue mb-5">
        <h1 className="title my-4">
          Top 5 most compelling use cases for blockchain technology
        </h1>
        <h4 className="small-text">
          The technical features of blockchains such as i) the use of
          distributed ledger technology ii) cryptographically secured iii) the
          ability to have smart contract logic encoded into it. This means block
          chains can allow multiple mutually untrusting users to transact
          transparently on a ledger ( smart contracts self execute transactions
          ) without the need for a trusted intermediary. <br />
          <br />
          The most compelling use cases which I believe will greatly reduce
          inefficiencies and unlock value are in areas of existing industry
          where trusted intermediaries are required to record, validate and
          reconcile transactions without really adding additional value to the
          original transaction.
        </h4>
        <h3 className="subtitle my-3">Asset Tokenization</h3>
        <h4 className="small-text">
          One of the most exciting uses cases is in the application in financial
          services and in particular asset tokenization in financial and real
          assets. Using blockchain technology, previously illiquid assets can
          now be converted into its tokenized form and cheaply and efficiently
          fractionalized, traded and settled on chain (rather than go through
          the lengthy process of clearing and settlement process through third
          parties like clearing houses). This can unlock liquidity for small
          business owners, entrepreneurs, residential real estate owners and
          alternative investments such as previously illiquid venture capital
          and private equity, commercial real estate and art. This means that
          access to investments that were previously only accessible by
          institutional investors can be accessible to retail investors.
          Tokenization of assets removes the frictions for an asset to be freely
          traded on a global market place and allows investors to diversify
          their investment across a larger opportunity set, enhances liquidity
          and market depth of assets that otherwise would not be actively traded
          and allows asset owners to capture a liquidity premium. AlphaPoint
          estimates that the total value of illiquid assets, including gold,
          real estate and more, is $11 trillion. <br />
          <br />
          Alphapoint, Polymath, Smart Varlor and Harbor are working on platforms
          for asset tokenization.
        </h4>
        <h3 className="subtitle my-3">Asset Tokenization</h3>
        <h4 className="small-text">
          One of the most exciting uses cases is in the application in financial
          services and in particular asset tokenization in financial and real
          assets. Using blockchain technology, previously illiquid assets can
          now be converted into its tokenized form and cheaply and efficiently
          fractionalized, traded and settled on chain (rather than go through
          the lengthy process of clearing and settlement process through third
          parties like clearing houses). This can unlock liquidity for small
          business owners, entrepreneurs, residential real estate owners and
          alternative investments such as previously illiquid venture capital
          and private equity, commercial real estate and art. This means that
          access to investments that were previously only accessible by
          institutional investors can be accessible to retail investors.
          Tokenization of assets removes the frictions for an asset to be freely
          traded on a global market place and allows investors to diversify
          their investment across a larger opportunity set, enhances liquidity
          and market depth of assets that otherwise would not be actively traded
          and allows asset owners to capture a liquidity premium. AlphaPoint
          estimates that the total value of illiquid assets, including gold,
          real estate and more, is $11 trillion. <br />
          <br />
          Alphapoint, Polymath, Smart Varlor and Harbor are working on platforms
          for asset tokenization.
        </h4>
        <h3 className="subtitle my-3">Supply Chain Management</h3>
        <h4 className="small-text">
          The second most compelling use case for blockchain is in the area of
          supply chain management. One of the biggest problems that firms face
          when managing their supply chain is the issue of transparency.
          Blockchains allow multiple parties to access a database to act as the
          single source of truth. Recorded transactions are immutable, are
          append only and provide a time stamped audit trail. Blockchains allow
          a product to be documented in real time as it moves from its original
          provenance and all it’s touch points. Blockchain technology helps
          enhance transparency into an otherwise opaque network, helps stop
          counterfeits and thefts, improves regulatory compliance, reduces
          paperwork and lessens costs significantly. From a consumer’s point of
          view, blockchains can empower end consumers to find out precisely if
          products are what they claim to be. <br />
          <br />
          Examples of projects working on this include Vechain and Origin Trail.
        </h4>
        <h3 className="subtitle my-3">Digital Identity</h3>
        <h4 className="small-text">
          The decentralised nature of blockchains means that there is no
          centralised point of weakness for hackers to target, which may lend
          itself as a good use case for digital identity management. A self
          sovereign ID can be used to verify identity without needing an
          individual to produce numerous documents and paperwork each time they
          need their identity verified. This could be done with a single key
          that can be matched against an immutable ledger. The digital ID can
          also collect other online information about a user’s identity like
          social security information, medical records and social media
          credentials and have that stored securely on the blockchain. This can
          allow users more control of their private data to transact more
          securely online but more importantly, takes away the power from
          companies to monetize this data and puts control back to the users.
          For those billions of people who are unbanked, allowing them to have a
          digital identity is the first step to help these people gain access to
          financial services in order for them to participate in the global
          economy. <br />
          <br />
          Examples of projects include Civic and Uport.
        </h4>
        <h3 className="subtitle my-3">Energy Market</h3>
        <h4 className="small-text">
          Another compelling use case for blockchain technology is the role it
          can play to decentralise the energy market, which is typically
          controlled by a few large corporations in each market. Blockchain
          technology enables the smart metering of electricity generated through
          an individual’s solar panels to be recorded, traded and settled on a
          ledger. If electricity can be traded like any other commodity, energy
          prices instead of being a fixed regulated price, will respond to
          forces of demand and supply in a fully functioning distributed
          electricity market. This allows individuals to be both producers and
          consumers of energy, which can reduce costs and improve efficiency by
          not having to rely on a centralised grid. <br />
          <br />
          Examples of peer to peer energy trading include Power Ledger and
          Grid+.
        </h4>
        <h3 className="subtitle my-3">Healthcare</h3>
        <h4 className="small-text">
          In the current state of healthcare, patient data is held across
          different institutions in legacy silos in various different formats
          and standards, making sharing of the data ill suited for the modern
          user’s expectation for instantaneous access. Using blockchain
          technology to record patient information on a distributed ledger can
          allow different stakeholders conditional access to a single source of
          truth where each interaction with a patient’s health data can be
          recorded on a ledger as a transaction with a time stamped audit trail.
          This makes access to a patient’s health information more secure
          (patient data is encrypted), can take out the inefficiencies with
          current data management practices and offers patients more control
          over their own health data (including monetizing their own health data
          for research purposes ). <br />
          <br />
          Examples of healthcare data exchange platforms include Medicalchain.
        </h4>
      </div>
    </div>
  );
};

export default Post2;
