import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import BlogPost from "./components/blog/BlogPost";
import Services from "./components/Services";
import Industry from "./components/Industry";
import Blog from "./components/blog/Blog";
import NotFound404 from "./pages/NotFound404";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GOOGLE_ANALYTICS_TRACKING_ID } from "./config/constants";
import ReactGA from "react-ga";
import "./App.css";
import "aos/dist/aos.css";
import ScrollToTop from "./components/ScrollToTop";

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);

function App() {
  return (
    <div className="App-Container">
      <Router>
        <Helmet>
          <title>
            Knackroot - Blockchain Development | NFT | Web3 | Wallet | DeFi
          </title>
          <meta
            name="description"
            content="Knackroot TechnoLabs is an IT consulting and solution provider of next-gen business collaborate with customers across the world. With tremendous experience, comprehensive intelligence system across diverse industries and domains, we work with customers to turn them into most successful and high-performance organizations."
          />
        </Helmet>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/blog/:post" element={<BlogPost />} />
          <Route path="/services/:serve" element={<Services />} />
          <Route path="/usecases/:industry" element={<Industry />} />
          <Route path="/*" element={<NotFound404 />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
