import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SERVICE_ROUTE } from "../config/constants";
import "../styles/Services.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const Services = () => {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname, "", "Service Page");
  }, []);

  const { serve } = useParams();
  const navigate = useNavigate();
  const {
    SMART_CONTRACT,
    PRIVATE_BLOCKCHAIN,
    CRYPTO_EXCHANGE,
    CRYPTO_WALLET,
    DEFI,
    MINING_SOFTWARE,
    NFT_MARKETPLACE,
    PUBLIC_BLOCKCHAIN,
    STO,
    TRADING_BOTS,
    WEB3_DEVELOPMENT,
    WEB3_MIGRATION,
  } = SERVICE_ROUTE;

  const setMetaTitle = () => {
    switch (serve) {
      case SMART_CONTRACT:
        return (
          <Helmet>
            <title> Knackroot - Smart Contract Development</title>
            <meta
              name="description"
              content="Smart contracts are self-executing contracts that are programmed to automatically execute when certain conditions are met. These contracts are built on blockchain technology, which provides a secure and transparent way to store and verify data."
            />
            <meta
              name="keywords"
              content="smart contract, smart contract development, blockchain smart contract, ethereum smart contract, erc20, erc721, erc1155"
            />
          </Helmet>
        );
      case PRIVATE_BLOCKCHAIN:
        return (
          <Helmet>
            <title> Knackroot - Private Blockchain Development</title>
            <meta
              name="description"
              content="Knackroot is a blockchain application development company that helps enterprises build top-notch applications over private blockchains."
            />
            <meta
              name="keywords"
              content="private blockchain, private blockchain development, secure private blockchain, private blockchain solutions, private blockchain network, private blockchain advantages"
            />
          </Helmet>
        );
      case CRYPTO_EXCHANGE:
        return (
          <Helmet>
            <title> Knackroot - Crypto Exchange Development</title>
            <meta
              name="description"
              content="Cryptocurrency exchanges, also known as digital currency exchanges (DCE) are online marketplaces that enable customers to trade cryptocurrencies or digital assets for other assets."
            />
            <meta
              name="keywords"
              content="crypto exchange, crypto exchange development, ethereum exchange, secure crypto exchange"
            />
          </Helmet>
        );
      case CRYPTO_WALLET:
        return (
          <Helmet>
            <title> Knackroot - Crypto Wallet Development</title>
            <meta
              name="description"
              content="Cryptocurrency wallets or crypto wallets are digitally secured systems that allow receiving and sending of cryptocurrencies, and the management of private and public cryptographic keys."
            />
            <meta
              name="keywords"
              content="crypto wallet, crypto wallet development, digital wallet development, decentralized wallet development, cryptocurrency wallet development, secure wallet development, crypto wallet integration"
            />
          </Helmet>
        );
      case DEFI:
        return (
          <Helmet>
            <title> Knackroot - DeFi Development</title>
            <meta
              name="description"
              content="At Knackroot, we specialize in providing top-notch DeFi development services for businesses and individuals who want to leverage the power of blockchain technology to create decentralized financial applications."
            />
            <meta
              name="keywords"
              content="decentralized finance, decentralized finance development, defi, tokenization, liquidity pools, staking"
            />
          </Helmet>
        );
      case MINING_SOFTWARE:
        return (
          <Helmet>
            <title> Knackroot - Mining Software Development</title>
            <meta
              name="description"
              content="Cryptocurrency mining, or cryptomining, is a process in which transactions for various forms of cryptocurrency are verified and added to the blockchain digital ledger."
            />
            <meta
              name="keywords"
              content="mining software, mining software development, mining software solutions, mining software tools, mining software platforms,  mining software integration, mining software optimization"
            />
          </Helmet>
        );
      case NFT_MARKETPLACE:
        return (
          <Helmet>
            <title> Knackroot - NFT Marketplace Development</title>
            <meta
              name="description"
              content="At Knackroot, we specialize in providing top-notch NFT marketplace development services for businesses and individuals who want to leverage the power of blockchain technology to create unique digital assets and showcase them to a global audience."
            />
            <meta
              name="keywords"
              content="nft marketplace, nft marketplace development, non fungible tokens, blockchain-based marketplace, crypto collectibles, digital art marketplace"
            />
          </Helmet>
        );
      case WEB3_DEVELOPMENT:
        return (
          <Helmet>
            <title> Knackroot - Web3 Development</title>
            <meta
              name="description"
              content="At Knackroot, we specialize in providing top-notch Web3 full-stack development services for businesses and individuals who want to leverage the power of decentralized applications and blockchain technology."
            />
            <meta
              name="keywords"
              content="web3, web3 development, decentralized applications, decentralized finance, ipfs, decentralized autonomous organizations, metamask"
            />
          </Helmet>
        );
      case WEB3_MIGRATION:
        return (
          <Helmet>
            <title> Knackroot - Web3 Migration</title>
            <meta
              name="description"
              content="Web2 to web3 migration refers to the process of transitioning a website or application from a traditional, centralized web2 platform to a decentralized, blockchain-based web3 platform."
            />
            <meta
              name="keywords"
              content="Web3 migration, decentralized migration, smart contract migration, token migration, decentralized web migration, decentralized finance migration, dapp migration, blockchain technology migration"
            />
          </Helmet>
        );
      case STO:
        return (
          <Helmet>
            <title> Knackroot - STO Development</title>
            <meta
              name="description"
              content="Security Token Offering (STO) is a type of fundraising that is performed with a company offering tokenized securities. Essentially, Security Tokens provide investors with an array of financial rights that are coded in smart contracts, with underlying tokens traded on the blockchain."
            />
            <meta
              name="keywords"
              content="sto, security token offering, sto development, tokenization, token offering platforms,tokenized assets, equity tokens, investment tokens"
            />
          </Helmet>
        );
      case TRADING_BOTS:
        return (
          <Helmet>
            <title> Knackroot - Trading Bots Development</title>
            <meta
              name="description"
              content="Cryptocurrencies are known for being incredibly volatile, with prices fluctuating dramatically even in the space of minutes. Investors also have the opportunity to take part in cryptocurrency trading around the world and at any hour of the day."
            />
            <meta
              name="keywords"
              content="trading bots, trading bots development, algorithmic trading, automated trading, cryptocurrency trading, trade automation, trading api, trading bot framework"
            />
          </Helmet>
        );
      case PUBLIC_BLOCKCHAIN:
        return (
          <Helmet>
            <title> Knackroot - Public Blockchain Development</title>
            <meta
              name="description"
              content="A Public Blockchain is a permissionless blockchain. Anyone can join the blockchain network, meaning that they can read, write, audit or participate with a public blockchain."
            />
            <meta
              name="keywords"
              content="public blockchain, public blockchain development, decentralized applications, distributed ledger technology, blockchain scalability, ethereum development, blockchain use cases"
            />
          </Helmet>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {setMetaTitle()}
      <div className="container-fluid bg-blue mt-2 p-4">
        <h1 className="title txt-white">Blockchain Development</h1>
      </div>
      <div className="container-fluid servicepg-main pt-5">
        <div className="servicepg-cover">
          <div className="tab-cover">
            <div
              className="nav-left nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <div
                className={`nav-link tab-text mt-2 ${
                  serve === SMART_CONTRACT ? "active" : ""
                }`}
                id="v-pills-smart-contract-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-smart-contract"
                type="button"
                role="tab"
                aria-controls="v-pills-smart-contract"
                aria-selected="true"
                onClick={() => navigate(`/services/${SMART_CONTRACT}`)}
              >
                <span> Smart Contract </span>
                <span>
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === PRIVATE_BLOCKCHAIN ? "active" : ""
                }`}
                id="v-pills-private-blc-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-private-blc"
                type="button"
                role="tab"
                aria-controls="v-pills-private-blc"
                aria-selected="false"
                onClick={() => navigate(`/services/${PRIVATE_BLOCKCHAIN}`)}
              >
                <span> Private Blockchain </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === PUBLIC_BLOCKCHAIN ? "active" : ""
                }`}
                id="v-pills-public-blc-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-public-blc"
                type="button"
                role="tab"
                aria-controls="v-pills-public-blc"
                aria-selected="false"
                onClick={() => navigate(`/services/${PUBLIC_BLOCKCHAIN}`)}
              >
                <span> Public Blockchain </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === WEB3_DEVELOPMENT ? "active" : ""
                }`}
                id="v-pills-web3-development-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-web3-development"
                type="button"
                role="tab"
                aria-controls="v-pills-web3-development"
                aria-selected="true"
                onClick={() => navigate(`/services/${WEB3_DEVELOPMENT}`)}
              >
                <span> Web3 FullStack Development </span>
                <span>
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === NFT_MARKETPLACE ? "active" : ""
                }`}
                id="v-pills-nft-marketplace-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-nft-marketplace"
                type="button"
                role="tab"
                aria-controls="v-pills-nft-marketplace"
                aria-selected="true"
                onClick={() => navigate(`/services/${NFT_MARKETPLACE}`)}
              >
                <span> NFT Marketplace Development </span>
                <span>
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === DEFI ? "active" : ""
                }`}
                id="v-pills-defi-development-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-defi-development"
                type="button"
                role="tab"
                aria-controls="v-pills-defi-development"
                aria-selected="true"
                onClick={() => navigate(`/services/${DEFI}`)}
              >
                <span> DeFi Development </span>
                <span>
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === WEB3_MIGRATION ? "active" : ""
                }`}
                id="v-pills-web3-migration-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-web3-migration"
                type="button"
                role="tab"
                aria-controls="v-pills-web3-migration"
                aria-selected="true"
                onClick={() => navigate(`/services/${WEB3_MIGRATION}`)}
              >
                <span> Web2 to Web3 Migration </span>
                <span>
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === MINING_SOFTWARE ? "active" : ""
                }`}
                id="v-pills-mining-soft-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-mining-soft"
                type="button"
                role="tab"
                aria-controls="v-pills-mining-soft"
                aria-selected="false"
                onClick={() => navigate(`/services/${MINING_SOFTWARE}`)}
              >
                <span> Mining Softwares </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === CRYPTO_EXCHANGE ? "active" : ""
                }`}
                id="v-pills-crypto-exc-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-crypto-exc"
                type="button"
                role="tab"
                aria-controls="v-pills-crypto-exc"
                aria-selected="false"
                onClick={() => navigate(`/services/${CRYPTO_EXCHANGE}`)}
              >
                <span> Crypto Exchange </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === CRYPTO_WALLET ? "active" : ""
                }`}
                id="v-pills-crypto-wallets-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-crypto-wallets"
                type="button"
                role="tab"
                aria-controls="v-pills-crypto-wallets"
                aria-selected="false"
                onClick={() => navigate(`/services/${CRYPTO_WALLET}`)}
              >
                <span> Crypto Wallets </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === TRADING_BOTS ? "active" : ""
                }`}
                id="v-pills-trad-bots-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-trad-bots"
                type="button"
                role="tab"
                aria-controls="v-pills-trad-bots"
                aria-selected="false"
                onClick={() => navigate(`/services/${TRADING_BOTS}`)}
              >
                <span> Trading Bots </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>

              <div
                className={`nav-link tab-text mt-4 ${
                  serve === STO ? "active" : ""
                }`}
                id="v-pills-sto-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-sto"
                type="button"
                role="tab"
                aria-controls="v-pills-sto"
                aria-selected="false"
                onClick={() => navigate(`/services/${STO}`)}
              >
                <span> STO </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>
            </div>
          </div>

          <div
            className="nav-right tab-content d-flex tab-content-cover"
            id="v-pills-tabContent"
          >
            <div
              className={`tab-pane fade show ${
                serve === SMART_CONTRACT ? "active" : ""
              }`}
              id="v-pills-smart-contract"
              role="tabpanel"
              aria-labelledby="v-pills-smart-contract-tab"
            >
              <h2 className="subtitle txt-blue">Smart Contract</h2>
              <h3 className="small-text txt-gray mt-4">
                <b>What are Smart Contracts ?</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Smart contracts are self-executing contracts that are programmed
                to automatically execute when certain conditions are met. These
                contracts are built on blockchain technology, which provides a
                secure and transparent way to store and verify data. Smart
                contracts can be used to automate complex business processes,
                reduce transaction costs, and increase trust between parties.
              </h3>
              <h3 className="small-text txt-gray mt-4">
                <b>Why Choose Us ?</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Our team of blockchain experts has years of experience in
                developing smart contracts for a variety of industries. We use
                the latest blockchain technologies and best practices to ensure
                that your smart contracts are secure, efficient, and scalable.
                Whether you need a simple smart contract for a small business or
                a complex contract for a large enterprise, we can help.
              </h3>
              <h3 className="small-text txt-gray mt-4">
                <b>Our Smart Contract Development Services Include :</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <ul>
                  <li className="mb-3">
                    Smart contract design and development
                  </li>
                  <li className="mb-3">
                    Integration with blockchain platforms such as Ethereum,
                    Polygon, Hyperledger Fabric, Corda, and more
                  </li>
                  <li className="mb-3">Smart contract auditing and testing</li>
                  <li className="mb-3">Maintenance and support</li>
                </ul>
              </h3>

              <h3 className="small-text txt-gray mt-4">
                <b>Benefits of Smart Contract Development :</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <ul>
                  <li className="mb-3">
                    Increased efficiency: Smart contracts automate complex
                    business processes, reducing the need for manual
                    intervention and saving time and resources.
                  </li>
                  <li className="mb-3">
                    Cost-effective: Smart contracts eliminate the need for
                    intermediaries and reduce transaction costs.
                  </li>
                  <li className="mb-3">
                    Secure and transparent: Smart contracts are built on
                    blockchain technology, which provides a secure and
                    transparent way to store and verify data.
                  </li>
                  <li className="mb-3">
                    Increased trust: Smart contracts are self-executing and
                    cannot be tampered with, increasing trust between parties.
                  </li>
                </ul>
              </h3>

              <h3 className="small-text txt-gray mt-4">
                <b>Contact Us Today !</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Ready to get started with smart contract development? Contact us
                today to learn more about our services and how we can help you
                streamline your business operations. Our team of blockchain
                experts is here to help you every step of the way.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${
                serve === PRIVATE_BLOCKCHAIN ? "active" : ""
              }`}
              id="v-pills-private-blc"
              role="tabpanel"
              aria-labelledby="v-pills-private-blc-tab"
            >
              <h2 className="subtitle txt-blue">Private Blockchain</h2>
              <h3 className="small-text txt-gray mt-5">
                Gone are the days when enterprises and financial institutions
                found blockchains unreliable due to their public nature. The
                advent of private blockchains has helped enterprises realize and
                scale the potential of distributed ledger technology while
                ensuring that their transaction data is only available to
                authorized parties.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Financial institutions</b> have started implementing private
                blockchains for more expeditious and secure inter-bank transfers
                and remittances system. Major brands such as Walmart and
                Starbucks also joined the blockchain bandwagon to track the
                authenticity of their raw products and derive more customer
                trust by delivering readily available product data to them.
                Shipping companies are using <b>private blockchains</b> to track
                and access cargo data to ease out custom’s process.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                That’s not all. Industries ranging from travel to oil and gas,
                from airlines to real estate, are all treading down the private
                blockchain path.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Hyperledger Fabric</b> by The Linux Foundation, R3’s Corda,
                and Quorum by JP Morgan are open source enterprise-focused
                blockchain platforms that are helping banks and businesses reap
                the benefits of blockchain. These blockchains provide utmost
                privacy to enterprises, a high transaction throughput, and a
                fraud-resistant ecosystem.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Knackroot</b> is a blockchain application development company
                that helps enterprises build top-notch applications over private
                blockchains.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Our experts then create a blueprint of your blockchain
                application idea to help you visualize how it would look in real
                life. Upon receiving a green flag from you on that, we develop
                an MVP to test your application in a testing environment,
                following which, we make the conclusive developments to ready
                the application for the final launch.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                If you are planning to build an application over a private
                blockchain or even have an idea about it, we would be eager to
                hear that out and discuss the best possibilities.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${
                serve === PUBLIC_BLOCKCHAIN ? "active" : ""
              }`}
              id="v-pills-public-blc"
              role="tabpanel"
              aria-labelledby="v-pills-public-blc-tab"
            >
              <h2 className="subtitle txt-blue">Public Blockchain</h2>
              <h3 className="small-text txt-gray mt-5">
                A Public Blockchain is a permissionless blockchain. Anyone can
                join the blockchain network, meaning that they can read, write,
                audit or participate with a public blockchain. Public
                blockchains are decentralised, no one has control over the
                network, and they are secure in that the data can’t be changed
                once validated on the blockchain.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                The information on every public blockchain is subsequently
                replicated to sometimes thousands of nodes on the network. No
                one power administers it centrally.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Example of Public Blockchain:
                <b>Bitcoin, Ethereum and Litecoin</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>BENEFITS OF PUBLIC BLOCKCHAIN</b> <br />
                <br />
                All cryptocurrencies operate on public blockchains. These
                include Bitcoin and Ethereum.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Some of the benefits of public blockchains are:
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Open Read and Write <br />
                <br />
                Anyone can participate by submitting transactions to the
                blockchain, such as Ethereum or Bitcoin; transactions can be
                viewed on the blockchain explorer.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Ledger Is Distributed
                <br />
                <br />
                The database is not centralised like in a client-server
                approach, and all nodes in the blockchain participate in the
                transaction validation.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Immutable
                <br />
                <br />
                When something is written to the blockchain, it can not be
                changed.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Secure Due to Mining (51% rule)
                <br />
                <br />
                For example, with Bitcoin, obtaining a majority of network power
                could potentially enable massive double spending, and the
                ability to prevent transaction confirmations, among other
                potentially malicious acts.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${
                serve === WEB3_DEVELOPMENT ? "active" : ""
              }`}
              id="v-pills-web3-development"
              role="tabpanel"
              aria-labelledby="v-pills-web3-development-tab"
            >
              <h2 className="subtitle txt-blue">Web3 Fullstack Development</h2>
              <h3 className="small-text txt-gray mt-5">
                At Knackroot, we specialize in providing top-notch Web3
                full-stack development services for businesses and individuals
                who want to leverage the power of decentralized applications and
                blockchain technology. Our team of skilled developers has
                extensive experience in Web3 development and can help you build
                secure, scalable, and decentralized applications.
              </h3>
              <h3 className="small-text txt-gray mt-4">
                <b>Why Choose Web3 Fullstack Development Services ?</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Web3 is the next generation of the internet, which promises to
                revolutionize the way we interact with digital content and
                services. It is built on decentralized blockchain technology,
                which offers a range of benefits such as transparency, security,
                and privacy. By using our Web3 Fullstack Development Services,
                you can benefit from:
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Decentralization :</b> Our development team has extensive
                experience in creating decentralized blockchain solutions. We
                will help you build a decentralized application that is free
                from any centralized control.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Security :</b> We understand the importance of security in
                Web3 development. We will make sure that your application is
                protected from any kind of cyber attack, ensuring the safety of
                your data and assets.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Scalability :</b> We design our Web3 applications to be
                highly scalable, allowing you to handle a large number of users
                and transactions without any issues.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>User-Friendly Interface :</b> Our Web3 applications are easy
                to use, making it simple for users to interact with your
                decentralized application.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                At Knackroot, we offer a wide range of Web3 Fullstack
                Development services to help you build decentralized
                applications that are tailored to your specific business needs.
                Our services include :
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Web3 Application Development :</b> We can help you design,
                develop, and launch your own Web3 application that is tailored
                to your specific business needs.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Smart Contract Development :</b> We can help you develop
                smart contracts that automate the process of exchanging digital
                assets on the blockchain.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Blockchain Integration :</b> We can help you integrate
                different blockchains into your Web3 application, allowing you
                to provide a wider range of digital asset exchange options.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Wallet Integration :</b> We can help you integrate secure
                wallets into your Web3 application to ensure the safe storage
                and transfer of digital assets.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>API Development :</b> We can help you build custom APIs to
                help you interact with different blockchains and decentralized
                services.
              </h3>
              <h3 className="small-text txt-gray mt-4">
                <b>Why Choose Knackroot ?</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                At Knackroot, we are committed to delivering high-quality Web3
                Fullstack Development services that meet the needs of our
                clients. Our team of expert developers has extensive experience
                in Web3 development and is dedicated to providing secure and
                reliable decentralized solutions. <br /> <br /> We work closely
                with our clients to understand their business goals and
                objectives, and we are committed to delivering solutions that
                exceed their expectations. We also provide ongoing support to
                ensure that your Web3 application is always up-to-date and
                secure. <br /> <br /> Contact us today to learn more about our
                Web3 Fullstack Development services and how we can help you
                build a secure and user-friendly decentralized application.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${
                serve === NFT_MARKETPLACE ? "active" : ""
              }`}
              id="v-pills-nft-marketplace"
              role="tabpanel"
              aria-labelledby="v-pills-nft-marketplace-tab"
            >
              <h2 className="subtitle txt-blue">NFT Marketplace Development</h2>
              <h3 className="small-text txt-gray mt-5">
                At Knackroot, we specialize in providing top-notch NFT
                marketplace development services for businesses and individuals
                who want to leverage the power of blockchain technology to
                create unique digital assets and showcase them to a global
                audience. Our team of skilled developers has extensive
                experience in blockchain technology and can help you build a
                secure, scalable, and user-friendly NFT marketplace.
              </h3>
              <h3 className="small-text txt-gray mt-4">
                <b>Why Choose NFT Marketplace Development Services ?</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Non-Fungible Tokens (NFTs) have become a popular and
                revolutionary approach to representing ownership of unique
                digital assets. NFTs offer a range of benefits such as ownership
                rights, scarcity, and authenticity. By using our NFT Marketplace
                Development Services, you can benefit from :
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Security :</b> We understand the importance of security in
                Web3 development. We will make sure that your application is
                protected from any kind of cyber attack, ensuring the safety of
                your data and assets.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Scalability :</b> We design our NFT marketplaces to be highly
                scalable, allowing you to handle a large number of users and
                transactions without any issues.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>User-Friendly Interface :</b> Our NFT marketplaces are easy
                to use, making it simple for users to showcase and trade their
                unique digital assets.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Our NFT Marketplace Development Services :
              </h3>
              <h3 className="small-text txt-gray mt-3">
                At Knackroot, we offer a wide range of NFT Marketplace
                Development services to help you build a digital asset
                marketplace that is tailored to your specific business needs.
                Our services include:
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>NFT Marketplace Development :</b> We can help you design,
                develop, and launch your own NFT marketplace that is tailored to
                your specific business needs.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Smart Contract Development :</b> We can help you develop
                smart contracts that automate the process of buying, selling,
                and trading NFTs.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Wallet Integration :</b> We can help you integrate secure
                wallets into your NFT marketplace to ensure the safe storage and
                transfer of digital assets.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Auction and Bidding Features :</b> We can help you implement
                auction and bidding features to allow users to buy and sell NFTs
                in a competitive environment.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Social Sharing Features :</b> We can help you add social
                sharing features to your NFT marketplace to allow users to
                showcase their unique digital assets on social media platforms.
              </h3>
              <h3 className="small-text txt-gray mt-4">
                <b>Why Choose Knackroot ?</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                At Knackroot, we are committed to delivering high-quality NFT
                Marketplace Development services that meet the needs of our
                clients. Our team of expert developers has extensive experience
                in blockchain technology and is dedicated to providing secure
                and reliable NFT marketplace solutions. <br /> <br /> We work
                closely with our clients to understand their business goals and
                objectives, and we are committed to delivering solutions that
                exceed their expectations. We also provide ongoing support to
                ensure that your NFT marketplace is always up-to-date and
                secure. <br /> <br /> Contact us today to learn more about our
                NFT Marketplace Development services and how we can help you
                build a secure and user-friendly digital asset marketplace.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${serve === DEFI ? "active" : ""}`}
              id="v-pills-defi-development"
              role="tabpanel"
              aria-labelledby="v-pills-defi-development-tab"
            >
              <h2 className="subtitle txt-blue">DeFi Development</h2>
              <h3 className="small-text txt-gray mt-5">
                At Knackroot, we specialize in providing top-notch DeFi
                development services for businesses and individuals who want to
                leverage the power of blockchain technology to create
                decentralized financial applications. Our team of skilled
                developers has extensive experience in blockchain technology and
                can help you build secure, scalable, and user-friendly DeFi
                applications.
              </h3>
              <h3 className="small-text txt-gray mt-4">
                <b>Why Choose Blockchain DeFi Development Services ?</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Decentralized Finance (DeFi) has emerged as a popular and
                revolutionary approach to financial systems that operates
                without intermediaries. It offers a range of benefits such as
                transparency, accessibility, and security. By using our
                Blockchain DeFi Development Services, you can benefit from :
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Security :</b> Our development team has extensive experience
                in creating secure blockchain solutions. We will make sure that
                your DeFi application is protected from any kind of cyber
                attack, ensuring the safety of your customers' funds.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Scalability :</b> We design our DeFi applications to be
                highly scalable, allowing you to handle a large number of users
                and transactions without any issues.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>User-Friendly Interface :</b> Our DeFi applications are easy
                to use, making it simple for users to access and use the
                decentralized financial services.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Our Blockchain DeFi Development Services :
              </h3>
              <h3 className="small-text txt-gray mt-3">
                At Knackroot, we offer a wide range of Blockchain DeFi
                Development services to help you build a decentralized financial
                application that is tailored to your specific business needs.
                Our services include :
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>DeFi Protocol Development :</b> We can help you design,
                develop, and launch your own decentralized financial protocol
                that is tailored to your specific business needs.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Decentralized Exchange (DEX) Development :</b> We can help
                you develop a decentralized exchange (DEX) that allows users to
                trade cryptocurrencies without intermediaries.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Decentralized Lending and Borrowing Platforms :</b>We can
                help you create decentralized lending and borrowing platforms
                that allow users to lend and borrow cryptocurrencies without
                intermediaries.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Asset Management Platforms :</b> We can help you create asset
                management platforms that allow users to manage their digital
                assets in a decentralized manner.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Smart Contract Development :</b> We can help you develop
                smart contracts that automate financial transactions and
                agreements, ensuring transparency and security.
              </h3>
              <h3 className="small-text txt-gray mt-4">
                <b>Why Choose Knackroot ?</b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                At Knackroot, we are committed to delivering high-quality
                Blockchain DeFi Development services that meet the needs of our
                clients. Our team of expert developers has extensive experience
                in blockchain technology and is dedicated to providing secure
                and reliable DeFi solutions. <br /> <br /> We work closely with
                our clients to understand their business goals and objectives,
                and we are committed to delivering solutions that exceed their
                expectations. We also provide ongoing support to ensure that
                your DeFi application is always up-to-date and secure.
                <br /> <br /> Contact us today to learn more about our
                Blockchain DeFi Development services and how we can help you
                build a secure and user-friendly decentralized financial
                application.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${
                serve === WEB3_MIGRATION ? "active" : ""
              }`}
              id="v-pills-web3-migration"
              role="tabpanel"
              aria-labelledby="v-pills-web3-migration-tab"
            >
              <h2 className="subtitle txt-blue">Web2 to Web3 Migration</h2>
              <h3 className="small-text txt-gray mt-5">
                Are you planning to migrate your web2 website to a
                blockchain-based web3 platform? We're here to help you make a
                smooth transition with our blockchain web3 migration development
                services.
              </h3>
              <h3 className="small-text txt-gray mt-4">
                Web2 to web3 migration refers to the process of transitioning a
                website or application from a traditional, centralized web2
                platform to a decentralized, blockchain-based web3 platform.
                This migration involves significant changes to the underlying
                infrastructure, protocols, and architecture of the website or
                application.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                The primary goal of migrating to web3 is to take advantage of
                the unique features and benefits of blockchain technology,
                including decentralization, transparency, security, and
                trustlessness. By moving to a web3 platform, businesses and
                developers can create more secure, transparent, and efficient
                applications that are resistant to censorship and tampering.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>
                  The process of migrating from web2 to web3 involves several
                  steps, including :
                </b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Identifying the reasons for migration and determining the
                benefits of web3 for your business or application.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Evaluating the current architecture of your web2 platform and
                designing a new architecture that is compatible with web3.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Selecting the appropriate blockchain platform or protocol, such
                as Ethereum, Polkadot, or Solana, to support your web3
                application.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Developing and testing new smart contracts and decentralized
                applications (dApps) to replace existing centralized services
                and functionalities.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Integrating blockchain-based features, such as tokenization,
                peer-to-peer transactions, and decentralized storage, into your
                web3 application.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Conducting thorough testing and debugging to ensure the
                stability, security, and performance of your web3 application.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Launching the new web3 application and transitioning users from
                the old web2 platform to the new web3 platform.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Migrating from web2 to web3 can be a complex and challenging
                process, but with the right planning, expertise, and resources,
                it can lead to significant benefits for your business or
                application.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                At our company, we specialize in blockchain web3 migration
                development services. Contact us today to learn more.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${
                serve === MINING_SOFTWARE ? "active" : ""
              }`}
              id="v-pills-mining-soft"
              role="tabpanel"
              aria-labelledby="v-pills-mining-soft-tab"
            >
              <h2 className="subtitle txt-blue">Mining Softwares</h2>
              <h3 className="small-text txt-gray mt-5">
                Cryptocurrency mining, or cryptomining, is a process in which
                transactions for various forms of <b>cryptocurrency</b> are
                verified and added to the blockchain digital ledger. Also known
                as cryptocoin mining, altcoin mining, or <b>Bitcoin mining</b>{" "}
                (for the most popular form of cryptocurrency, Bitcoin),
                cryptocurrency mining has increased both as a topic and activity
                as cryptocurrency usage itself has grown exponentially in the
                last few years.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Merged mining</b> allows a miner to mine for more than one
                block chain at the same time. The benefit is that every hash the
                miner does contribute to the total hash rate of both (all)
                currencies, and as a result they are all more secure.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Knackroot</b> is a mining software development company that
                offers the most efficient, robust, stable mining softwares built
                on ASIC, GPU and CPU based mining hardware. The expert team of
                Knackroot will help to build <b>Mining Pool</b>
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${
                serve === CRYPTO_EXCHANGE ? "active" : ""
              }`}
              id="v-pills-crypto-exc"
              role="tabpanel"
              aria-labelledby="v-pills-crypto-exc-tab"
            >
              <h2 className="subtitle txt-blue">Crypto Exchanges</h2>
              <h3 className="small-text txt-gray mt-5">
                Cryptocurrency exchanges, also known as digital currency
                exchanges (DCE) are online marketplaces that enable customers to
                trade cryptocurrencies or digital assets for other assets, such
                as conventional fiat currencies (USD, Euro, CHF, RMB, etc.), or
                other cryptocurrencies.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Often, cryptocurrency exchanges are liquidity providers that
                take bid/ask spreads. Most of the
                <b>decentralized exchanges (DEC)</b> do not store customers
                funds on the exchange. Instead, they facilitate direct,
                peer-to-peer cryptocurrency trading, meaning that customers
                store their funds in their own wallets. Because of this, a hack
                of a DCE does not pose any threat to users’ funds.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Cryptocurrency exchanges have become an integral part of the
                <b>crypto-asset ecosystem</b>. They solve numerous operational
                issues faced in the traditional banking and fintech industries,
                including, but not limited to, high transaction fees, slow
                processing time, need for intermediaries, and digital identity
                challenges.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <b>Knackroot</b> is a blockchain development company that
                specializes in cryptocurrency exchange development. Our
                cryptocurrency exchange development experts are on par with the
                latest trends in the blockchain development arena, and they are
                focused on building robust end-to-end blockchain-based
                solutions.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Our proficiently devised cutting-edge development framework
                handles each stage of the
                <b>cryptocurrency exchange development</b> with precision while
                being in complete accordance with your specifications.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                1. We start off by clarifying business and technical
                requirements to align business logic with the product’s end
                result. The exchange ecosystem — centralized or decentralized —
                is determined and then we further delve into efforts estimation
                and roadmap creation.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                2. During the Design stage, we develop conceptual, logical and
                sequential architecture diagrams with system blueprint, and
                high-level list of DCE features and functionalities. Depending
                on the business revenue streams, we determine which trading
                instruments become an integral part of the trading environment.
                Futures, swaps, margin trading, and other instruments will be
                assessed and eventually integrated.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                3. After defining the technical and trading components, we move
                into the Development stage. Under your supervision, we will
                leverage our blockchain expertise to launch your product’s MVP
                in the testnet environment to conduct integration and
                performance testing, and to validate cutover plans.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                4. The ultimate stage of the cryptocurrency exchange development
                is the Deployment phase when production ready cryptocurrency
                exchange will be launched and run on the mainnet.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${
                serve === CRYPTO_WALLET ? "active" : ""
              }`}
              id="v-pills-crypto-wallets"
              role="tabpanel"
              aria-labelledby="v-pills-crypto-wallets-tab"
            >
              <h2 className="subtitle txt-blue">Crypto Wallets</h2>
              <h3 className="small-text txt-gray mt-5">
                Cryptocurrency wallets or crypto wallets are digitally secured
                systems that allow receiving and sending of
                <b>cryptocurrencies</b>, and the management of
                <b>private and public cryptographic keys</b>. Crypto wallets are
                similar to regular bank accounts, just that they make it
                possible to securely buy, sell and store cryptocurrencies in
                place of fiat currencies.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Unlike banks accounts or pocket wallets, cryptocurrencies can
                only be transacted, traded, recorded and stored digitally over a
                blockchain. Therefore, for any cryptocurrency trading
                transaction crypto users require a crypto wallet. Cryptocurrency
                transfer to a crypto wallet means that the ownership to specific
                wallet address has been signed-off.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Based on the customer requirements, Knackroot creates a
                cryptocurrency coin or token. Source code and Crypto Wallet
                product with incorporated security and privacy will be issued
                out.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Our crypto wallet development services consist of :
              </h3>
              <h3 className="small-text txt-gray mt-3">
                1. Desktop Crypto Wallet Development <br />
                2. Android Crypto Wallet Development <br />
                3. iOS Crypto Wallet Development <br />
                4. Private and Public Keys Management <br />
                5. Multi-cryptocurrency Crypto Wallet Development <br />
                6. Electrum Wallet ( Light Wallet ) <br />
                7. TrustWallet Integration <br />
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Knackroot crypto wallet development offering includes among
                other the following feature set :
              </h3>
              <h3 className="small-text txt-gray mt-3">
                1. <b>Two-factor authentication</b> to ensure security <br />
                2. Generation of a new public key for every new transaction{" "}
                <br />
                3. Rejection of duplicate payments to circumvent chargebacks
                <br />
                4. Timely session logoutt <br />
                5. QR code scanner <br />
                6. Push notifications for cryptocurrencies price changes <br />
                7. Paper crypto wallet development <br />
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Knackroot team follows agile software development methodology to
                rapidly deliver superb blockchain code by applying the
                proprietary framework that includes business requirements
                analysis, design, development, and blockchain code deployment.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${
                serve === TRADING_BOTS ? "active" : ""
              }`}
              id="v-pills-trad-bots"
              role="tabpanel"
              aria-labelledby="v-pills-trad-bots-tab"
            >
              <h2 className="subtitle txt-blue">Trading Bots</h2>
              <h3 className="small-text txt-gray mt-5">
                Cryptocurrencies are known for being incredibly volatile, with
                prices fluctuating dramatically even in the space of minutes.
                Investors also have the opportunity to take part in
                cryptocurrency trading around the world and at any hour of the
                day. Combined, these factors limit the effectiveness of human
                cryptocurrency trading in several ways.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Fortunately for many investors, there are solutions to these
                issues. One of the primary solutions is bots, or automated tools
                that conduct trades and execute transactions on behalf of human
                investors.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                There are many varieties of cryptocurrency bots. One of the most
                popular types is the Arbitrage Bot. <b>Arbitrage</b> bots are
                tools that examine prices across exchanges and make trades in
                order to take advantage of discrepancies. Because the price of a
                cryptocurrency like bitcoin tends to vary somewhat from exchange
                to exchange, bots that can move fast enough can beat exchanges
                that are delayed in updating their prices
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Other types of bots use historical price data to test out
                trading strategies, theoretically offering investors a leg up.
                Still other bots are programmed to execute trades at particular
                signals such as price or trading volume.
              </h3>
            </div>

            <div
              className={`tab-pane fade show ${serve === STO ? "active" : ""}`}
              id="v-pills-sto"
              role="tabpanel"
              aria-labelledby="v-pills-sto-tab"
            >
              <h2 className="subtitle txt-blue">STO</h2>
              <h3 className="small-text txt-gray mt-5">
                Security Token Offering (STO) is a type of fundraising that is
                performed with a company offering tokenized securities.
                Essentially, Security Tokens provide investors with an array of
                financial rights that are coded in <b>smart contracts</b>, with
                underlying tokens traded on the blockchain. Generally,
                undertaking an STO venture is a daring move. It incorporates
                creation and distribution of digital assets on a secure internet
                hub. The success of the company directly dictates the value of
                the asset which is traded on exchanges.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                STOs have regularly been linked with often controversial ICOs,
                however, STOs are always backed by some form of tangible asset
                which prevents investors from falling prey to fraudulent
                business activities. Due to the fact that STOs are classed as
                securities, they are also subject to securities regulations for
                the country they are launched in and for the countries of their
                investors.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Knackroot is a leading STO development company enabling
                entrepreneurs and companies to produce sustainable business
                success, by providing value-driven blockchain services and
                solutions. We specialize in delivering STO launch services,
                built upon next-generation disruptive technologies. Our team of
                agile blockchain development experts provides smart contract
                development services in
                <b>
                  Solidity, Stellar, Quorum, Hyperledger Fabric, Lysk and EOS.
                </b>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Our cutting-edge development processes have been carefully
                devised to handle each stage robustly and with full accordance
                to your specifications.
              </h3>
              <h3 className="small-text txt-gray mt-3">
                <ol>
                  <li className="mb-3">
                    Generate list of business requirements essential for
                    comprehensive analysis and token model creation
                  </li>
                  <li className="mb-3">
                    Preparing a conceptual, logical and sequential
                    blockchain-architecture design based on features set
                  </li>
                  <li className="mb-3">
                    Development of Token on the desired network according to the
                    tokenization model
                  </li>
                  <li className="mb-3">
                    Development of the technical platform for STO with KYC/AML
                  </li>
                  <li className="mb-3">
                    Once the token is audited, assessed, and verified for
                    successful launch, project will be migrated to production
                    environment
                  </li>
                </ol>
              </h3>
              <h3 className="small-text txt-gray mt-3">
                Knackroot offers full cycle STO Launch Services to help
                companies yield the highest return on investment.
              </h3>
            </div>
          </div>
        </div>

        <div className="sp-cover">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingSmartContract">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSmartContract"
                  aria-expanded="true"
                  aria-controls="collapseSmartContract"
                >
                  Smart Contract
                </button>
              </h3>
              <div
                id="collapseSmartContract"
                className="accordion-collapse collapse show"
                aria-labelledby="headingSmartContract"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray">
                    <b>What are Smart Contracts ?</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Smart contracts are self-executing contracts that are
                    programmed to automatically execute when certain conditions
                    are met. These contracts are built on blockchain technology,
                    which provides a secure and transparent way to store and
                    verify data. Smart contracts can be used to automate complex
                    business processes, reduce transaction costs, and increase
                    trust between parties.
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Why Choose Us ?</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Our team of blockchain experts has years of experience in
                    developing smart contracts for a variety of industries. We
                    use the latest blockchain technologies and best practices to
                    ensure that your smart contracts are secure, efficient, and
                    scalable. Whether you need a simple smart contract for a
                    small business or a complex contract for a large enterprise,
                    we can help.
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Our Smart Contract Development Services Include :</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <ul>
                      <li className="mb-3">
                        Smart contract design and development
                      </li>
                      <li className="mb-3">
                        Integration with blockchain platforms such as Ethereum,
                        Polygon, Hyperledger Fabric, Corda, and more
                      </li>
                      <li className="mb-3">
                        Smart contract auditing and testing
                      </li>
                      <li className="mb-3">Maintenance and support</li>
                    </ul>
                  </h3>

                  <h3 className="small-text txt-gray mt-4">
                    <b>Benefits of Smart Contract Development :</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <ul>
                      <li className="mb-3">
                        Increased efficiency: Smart contracts automate complex
                        business processes, reducing the need for manual
                        intervention and saving time and resources.
                      </li>
                      <li className="mb-3">
                        Cost-effective: Smart contracts eliminate the need for
                        intermediaries and reduce transaction costs.
                      </li>
                      <li className="mb-3">
                        Secure and transparent: Smart contracts are built on
                        blockchain technology, which provides a secure and
                        transparent way to store and verify data.
                      </li>
                      <li className="mb-3">
                        Increased trust: Smart contracts are self-executing and
                        cannot be tampered with, increasing trust between
                        parties.
                      </li>
                    </ul>
                  </h3>

                  <h3 className="small-text txt-gray mt-4">
                    <b>Contact Us Today !</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Ready to get started with smart contract development?
                    Contact us today to learn more about our services and how we
                    can help you streamline your business operations. Our team
                    of blockchain experts is here to help you every step of the
                    way.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingPrivateBlockchain">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsePrivateBlockchain"
                  aria-expanded="false"
                  aria-controls="collapsePrivateBlockchain"
                >
                  Private Blockchain
                </button>
              </h3>
              <div
                id="collapsePrivateBlockchain"
                className="accordion-collapse collapse"
                aria-labelledby="headingPrivateBlockchain"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray">
                    Gone are the days when enterprises and financial
                    institutions found blockchains unreliable due to their
                    public nature. The advent of private blockchains has helped
                    enterprises realize and scale the potential of distributed
                    ledger technology while ensuring that their transaction data
                    is only available to authorized parties.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Financial institutions have started implementing private
                    blockchains for more expeditious and secure inter-bank
                    transfers and remittances system. Major brands such as
                    Walmart and Starbucks also joined the blockchain bandwagon
                    to track the authenticity of their raw products and derive
                    more customer trust by delivering readily available product
                    data to them. Shipping companies are using private
                    blockchains to track and access cargo data to ease out
                    custom’s process.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    That’s not all. Industries ranging from travel to oil and
                    gas, from airlines to real estate, are all treading down the
                    private blockchain path.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Hyperledger Fabric by The Linux Foundation, R3’s Corda, and
                    Quorum by JP Morgan are open source enterprise-focused
                    blockchain platforms that are helping banks and businesses
                    reap the benefits of blockchain. These blockchains provide
                    utmost privacy to enterprises, a high transaction
                    throughput, and a fraud-resistant ecosystem.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Knackroot is a blockchain application development company
                    that helps enterprises build top-notch applications over
                    private blockchains.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Our experts then create a blueprint of your blockchain
                    application idea to help you visualize how it would look in
                    real life. Upon receiving a green flag from you on that, we
                    develop an MVP to test your application in a testing
                    environment, following which, we make the conclusive
                    developments to ready the application for the final launch.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    If you are planning to build an application over a private
                    blockchain or even have an idea about it, we would be eager
                    to hear that out and discuss the best possibilities.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingPublicBlockchain">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsePublicBlockchain"
                  aria-expanded="false"
                  aria-controls="collapsePublicBlockchain"
                >
                  Public Blockchain
                </button>
              </h3>
              <div
                id="collapsePublicBlockchain"
                className="accordion-collapse collapse"
                aria-labelledby="headingPublicBlockchain"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray">
                    A Public Blockchain is a permissionless blockchain. Anyone
                    can join the blockchain network, meaning that they can read,
                    write, audit or participate with a public blockchain.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Public blockchains are decentralised, no one has control
                    over the network, and they are secure in that the data can’t
                    be changed once validated on the blockchain.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    The information on every public blockchain is subsequently
                    replicated to sometimes thousands of nodes on the network.
                    No one power administers it centrally.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    BENEFITS OF PUBLIC BLOCKCHAIN <br />
                    <br />
                    All cryptocurrencies operate on public blockchains. These
                    include Bitcoin and Ethereum.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Some of the benefits of public blockchains are:
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Open Read and Write <br />
                    <br />
                    Anyone can participate by submitting transactions to the
                    blockchain, such as Ethereum or Bitcoin; transactions can be
                    viewed on the blockchain explorer.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Ledger Is Distributed
                    <br />
                    <br />
                    The database is not centralised like in a client-server
                    approach, and all nodes in the blockchain participate in the
                    transaction validation.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Immutable
                    <br />
                    <br />
                    When something is written to the blockchain, it can not be
                    changed.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Secure Due to Mining (51% rule)
                    <br />
                    <br />
                    For example, with Bitcoin, obtaining a majority of network
                    power could potentially enable massive double spending, and
                    the ability to prevent transaction confirmations, among
                    other potentially malicious acts.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingWeb3Development">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseWeb3Development"
                  aria-controls="collapseWeb3Development"
                >
                  Web3 FullStack Development
                </button>
              </h3>
              <div
                id="collapseWeb3Development"
                className="accordion-collapse collapse"
                aria-labelledby="headingWeb3Development"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray mt-1">
                    At Knackroot, we specialize in providing top-notch Web3
                    full-stack development services for businesses and
                    individuals who want to leverage the power of decentralized
                    applications and blockchain technology. Our team of skilled
                    developers has extensive experience in Web3 development and
                    can help you build secure, scalable, and decentralized
                    applications.
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Why Choose Web3 Fullstack Development Services ?</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Web3 is the next generation of the internet, which promises
                    to revolutionize the way we interact with digital content
                    and services. It is built on decentralized blockchain
                    technology, which offers a range of benefits such as
                    transparency, security, and privacy. By using our Web3
                    Fullstack Development Services, you can benefit from:
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Decentralization :</b> Our development team has extensive
                    experience in creating decentralized blockchain solutions.
                    We will help you build a decentralized application that is
                    free from any centralized control.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Security :</b> We understand the importance of security
                    in Web3 development. We will make sure that your application
                    is protected from any kind of cyber attack, ensuring the
                    safety of your data and assets.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Scalability :</b> We design our Web3 applications to be
                    highly scalable, allowing you to handle a large number of
                    users and transactions without any issues.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>User-Friendly Interface :</b> Our Web3 applications are
                    easy to use, making it simple for users to interact with
                    your decentralized application.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    At Knackroot, we offer a wide range of Web3 Fullstack
                    Development services to help you build decentralized
                    applications that are tailored to your specific business
                    needs. Our services include :
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Web3 Application Development :</b> We can help you
                    design, develop, and launch your own Web3 application that
                    is tailored to your specific business needs.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Smart Contract Development :</b> We can help you develop
                    smart contracts that automate the process of exchanging
                    digital assets on the blockchain.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Blockchain Integration :</b> We can help you integrate
                    different blockchains into your Web3 application, allowing
                    you to provide a wider range of digital asset exchange
                    options.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Wallet Integration :</b> We can help you integrate secure
                    wallets into your Web3 application to ensure the safe
                    storage and transfer of digital assets.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>API Development :</b> We can help you build custom APIs
                    to help you interact with different blockchains and
                    decentralized services.
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Why Choose Knackroot ?</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    At Knackroot, we are committed to delivering high-quality
                    Web3 Fullstack Development services that meet the needs of
                    our clients. Our team of expert developers has extensive
                    experience in Web3 development and is dedicated to providing
                    secure and reliable decentralized solutions. <br /> <br />{" "}
                    We work closely with our clients to understand their
                    business goals and objectives, and we are committed to
                    delivering solutions that exceed their expectations. We also
                    provide ongoing support to ensure that your Web3 application
                    is always up-to-date and secure. <br /> <br /> Contact us
                    today to learn more about our Web3 Fullstack Development
                    services and how we can help you build a secure and
                    user-friendly decentralized application.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingNFTMarketplace">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNFTMarketplace"
                  aria-controls="collapseNFTMarketplace"
                >
                  NFT Marketplace Development
                </button>
              </h3>
              <div
                id="collapseNFTMarketplace"
                className="accordion-collapse collapse"
                aria-labelledby="headingNFTMarketplace"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray mt-1">
                    At Knackroot, we specialize in providing top-notch NFT
                    marketplace development services for businesses and
                    individuals who want to leverage the power of blockchain
                    technology to create unique digital assets and showcase them
                    to a global audience. Our team of skilled developers has
                    extensive experience in blockchain technology and can help
                    you build a secure, scalable, and user-friendly NFT
                    marketplace.
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Why Choose NFT Marketplace Development Services ?</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Non-Fungible Tokens (NFTs) have become a popular and
                    revolutionary approach to representing ownership of unique
                    digital assets. NFTs offer a range of benefits such as
                    ownership rights, scarcity, and authenticity. By using our
                    NFT Marketplace Development Services, you can benefit from :
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Security :</b> We understand the importance of security
                    in Web3 development. We will make sure that your application
                    is protected from any kind of cyber attack, ensuring the
                    safety of your data and assets.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Scalability :</b> We design our NFT marketplaces to be
                    highly scalable, allowing you to handle a large number of
                    users and transactions without any issues.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>User-Friendly Interface :</b> Our NFT marketplaces are
                    easy to use, making it simple for users to showcase and
                    trade their unique digital assets.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Our NFT Marketplace Development Services :
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    At Knackroot, we offer a wide range of NFT Marketplace
                    Development services to help you build a digital asset
                    marketplace that is tailored to your specific business
                    needs. Our services include:
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>NFT Marketplace Development :</b> We can help you design,
                    develop, and launch your own NFT marketplace that is
                    tailored to your specific business needs.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Smart Contract Development :</b> We can help you develop
                    smart contracts that automate the process of buying,
                    selling, and trading NFTs.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Wallet Integration :</b> We can help you integrate secure
                    wallets into your NFT marketplace to ensure the safe storage
                    and transfer of digital assets.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Auction and Bidding Features :</b> We can help you
                    implement auction and bidding features to allow users to buy
                    and sell NFTs in a competitive environment.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Social Sharing Features :</b> We can help you add social
                    sharing features to your NFT marketplace to allow users to
                    showcase their unique digital assets on social media
                    platforms.
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Why Choose Knackroot ?</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    At Knackroot, we are committed to delivering high-quality
                    NFT Marketplace Development services that meet the needs of
                    our clients. Our team of expert developers has extensive
                    experience in blockchain technology and is dedicated to
                    providing secure and reliable NFT marketplace solutions.{" "}
                    <br /> <br /> We work closely with our clients to understand
                    their business goals and objectives, and we are committed to
                    delivering solutions that exceed their expectations. We also
                    provide ongoing support to ensure that your NFT marketplace
                    is always up-to-date and secure. <br /> <br /> Contact us
                    today to learn more about our NFT Marketplace Development
                    services and how we can help you build a secure and
                    user-friendly digital asset marketplace.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingDeFiDevelopment">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseDeFiDevelopment"
                  aria-controls="collapseDeFiDevelopment"
                >
                  DeFi Development
                </button>
              </h3>
              <div
                id="collapseDeFiDevelopment"
                className="accordion-collapse collapse"
                aria-labelledby="headingDeFiDevelopment"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray mt-1">
                    At Knackroot, we specialize in providing top-notch DeFi
                    development services for businesses and individuals who want
                    to leverage the power of blockchain technology to create
                    decentralized financial applications. Our team of skilled
                    developers has extensive experience in blockchain technology
                    and can help you build secure, scalable, and user-friendly
                    DeFi applications.
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Why Choose Blockchain DeFi Development Services ?</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Decentralized Finance (DeFi) has emerged as a popular and
                    revolutionary approach to financial systems that operates
                    without intermediaries. It offers a range of benefits such
                    as transparency, accessibility, and security. By using our
                    Blockchain DeFi Development Services, you can benefit from :
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Security :</b> Our development team has extensive
                    experience in creating secure blockchain solutions. We will
                    make sure that your DeFi application is protected from any
                    kind of cyber attack, ensuring the safety of your customers'
                    funds.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Scalability :</b> We design our DeFi applications to be
                    highly scalable, allowing you to handle a large number of
                    users and transactions without any issues.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>User-Friendly Interface :</b> Our DeFi applications are
                    easy to use, making it simple for users to access and use
                    the decentralized financial services.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Our Blockchain DeFi Development Services :
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    At Knackroot, we offer a wide range of Blockchain DeFi
                    Development services to help you build a decentralized
                    financial application that is tailored to your specific
                    business needs. Our services include :
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>DeFi Protocol Development :</b> We can help you design,
                    develop, and launch your own decentralized financial
                    protocol that is tailored to your specific business needs.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Decentralized Exchange (DEX) Development :</b> We can
                    help you develop a decentralized exchange (DEX) that allows
                    users to trade cryptocurrencies without intermediaries.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Decentralized Lending and Borrowing Platforms :</b>We can
                    help you create decentralized lending and borrowing
                    platforms that allow users to lend and borrow
                    cryptocurrencies without intermediaries.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Asset Management Platforms :</b> We can help you create
                    asset management platforms that allow users to manage their
                    digital assets in a decentralized manner.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>Smart Contract Development :</b> We can help you develop
                    smart contracts that automate financial transactions and
                    agreements, ensuring transparency and security.
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Why Choose Knackroot ?</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    At Knackroot, we are committed to delivering high-quality
                    Blockchain DeFi Development services that meet the needs of
                    our clients. Our team of expert developers has extensive
                    experience in blockchain technology and is dedicated to
                    providing secure and reliable DeFi solutions. <br /> <br />{" "}
                    We work closely with our clients to understand their
                    business goals and objectives, and we are committed to
                    delivering solutions that exceed their expectations. We also
                    provide ongoing support to ensure that your DeFi application
                    is always up-to-date and secure.
                    <br /> <br /> Contact us today to learn more about our
                    Blockchain DeFi Development services and how we can help you
                    build a secure and user-friendly decentralized financial
                    application.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingWeb3Migration">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseWeb3Migration"
                  aria-controls="collapseWeb3Migration"
                >
                  Web2 to Web3 Migration
                </button>
              </h3>
              <div
                id="collapseWeb3Migration"
                className="accordion-collapse collapse"
                aria-labelledby="headingWeb3Migration"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-2">
                  <h3 className="small-text txt-gray mt-1">
                    Are you planning to migrate your web2 website to a
                    blockchain-based web3 platform? We're here to help you make
                    a smooth transition with our blockchain web3 migration
                    development services.
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    Web2 to web3 migration refers to the process of
                    transitioning a website or application from a traditional,
                    centralized web2 platform to a decentralized,
                    blockchain-based web3 platform. This migration involves
                    significant changes to the underlying infrastructure,
                    protocols, and architecture of the website or application.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    The primary goal of migrating to web3 is to take advantage
                    of the unique features and benefits of blockchain
                    technology, including decentralization, transparency,
                    security, and trustlessness. By moving to a web3 platform,
                    businesses and developers can create more secure,
                    transparent, and efficient applications that are resistant
                    to censorship and tampering.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <b>
                      The process of migrating from web2 to web3 involves
                      several steps, including :
                    </b>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Identifying the reasons for migration and determining the
                    benefits of web3 for your business or application.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Evaluating the current architecture of your web2 platform
                    and designing a new architecture that is compatible with
                    web3.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Selecting the appropriate blockchain platform or protocol,
                    such as Ethereum, Polkadot, or Solana, to support your web3
                    application.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Developing and testing new smart contracts and decentralized
                    applications (dApps) to replace existing centralized
                    services and functionalities.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Integrating blockchain-based features, such as tokenization,
                    peer-to-peer transactions, and decentralized storage, into
                    your web3 application.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Conducting thorough testing and debugging to ensure the
                    stability, security, and performance of your web3
                    application.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Launching the new web3 application and transitioning users
                    from the old web2 platform to the new web3 platform.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Migrating from web2 to web3 can be a complex and challenging
                    process, but with the right planning, expertise, and
                    resources, it can lead to significant benefits for your
                    business or application.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    At our company, we specialize in blockchain web3 migration
                    development services. Contact us today to learn more.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-controls="collapseFour"
                >
                  Mining Softwares
                </button>
              </h3>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray">
                    Cryptocurrency mining, or cryptomining, is a process in
                    which transactions for various forms of cryptocurrency are
                    verified and added to the blockchain digital ledger. Also
                    known as cryptocoin mining, altcoin mining, or Bitcoin
                    mining (for the most popular form of cryptocurrency,
                    Bitcoin), cryptocurrency mining has increased both as a
                    topic and activity as cryptocurrency usage itself has grown
                    exponentially in the last few years.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Merged mining allows a miner to mine for more than one block
                    chain at the same time. The benefit is that every hash the
                    miner does contribute to the total hash rate of both (all)
                    currencies, and as a result they are all more secure.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Knackroot is a mining software development company that
                    offers the most efficient, robust, stable mining softwares
                    built on ASIC, GPU and CPU based mining hardware. The expert
                    team of Knackroot will help to build Mining Pool
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Crypto Exchanges
                </button>
              </h3>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray">
                    Cryptocurrency exchanges, also known as digital currency
                    exchanges (DCE) are online marketplaces that enable
                    customers to trade cryptocurrencies or digital assets for
                    other assets, such as conventional fiat currencies (USD,
                    Euro, CHF, RMB, etc.), or other cryptocurrencies.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Often, cryptocurrency exchanges are liquidity providers that
                    take bid/ask spreads. Most of the decentralized exchanges
                    (DEC) do not store customers funds on the exchange. Instead,
                    they facilitate direct, peer-to-peer cryptocurrency trading,
                    meaning that customers store their funds in their own
                    wallets. Because of this, a hack of a DCE does not pose any
                    threat to users’ funds.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Cryptocurrency exchanges have become an integral part of the
                    crypto-asset ecosystem. They solve numerous operational
                    issues faced in the traditional banking and fintech
                    industries, including, but not limited to, high transaction
                    fees, slow processing time, need for intermediaries, and
                    digital identity challenges.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Knackroot is a blockchain development company that
                    specializes in cryptocurrency exchange development. Our
                    cryptocurrency exchange development experts are on par with
                    the latest trends in the blockchain development arena, and
                    they are focused on building robust end-to-end
                    blockchain-based solutions.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Our proficiently devised cutting-edge development framework
                    handles each stage of the cryptocurrency exchange
                    development with precision while being in complete
                    accordance with your specifications.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    1. We start off by clarifying business and technical
                    requirements to align business logic with the product’s end
                    result. The exchange ecosystem — centralized or
                    decentralized — is determined and then we further delve into
                    efforts estimation and roadmap creation.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    2. During the Design stage, we develop conceptual, logical
                    and sequential architecture diagrams with system blueprint,
                    and high-level list of DCE features and functionalities.
                    Depending on the business revenue streams, we determine
                    which trading instruments become an integral part of the
                    trading environment. Futures, swaps, margin trading, and
                    other instruments will be assessed and eventually
                    integrated.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    3. After defining the technical and trading components, we
                    move into the Development stage. Under your supervision, we
                    will leverage our blockchain expertise to launch your
                    product’s MVP in the testnet environment to conduct
                    integration and performance testing, and to validate cutover
                    plans.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    4. The ultimate stage of the cryptocurrency exchange
                    development is the Deployment phase when production ready
                    cryptocurrency exchange will be launched and run on the
                    mainnet.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Crypto Wallets
                </button>
              </h3>
              <div
                id="collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray">
                    Cryptocurrency wallets or crypto wallets are digitally
                    secured systems that allow receiving and sending of
                    cryptocurrencies, and the management of private and public
                    cryptographic keys. Crypto wallets are similar to regular
                    bank accounts, just that they make it possible to securely
                    buy, sell and store cryptocurrencies in place of fiat
                    currencies.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Unlike banks accounts or pocket wallets, cryptocurrencies
                    can only be transacted, traded, recorded and stored
                    digitally over a blockchain. Therefore, for any
                    cryptocurrency trading transaction crypto users require a
                    crypto wallet. Cryptocurrency transfer to a crypto wallet
                    means that the ownership to specific wallet address has been
                    signed-off.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Based on the customer requirements, Knackroot creates a
                    cryptocurrency coin or token. Source code and Crypto Wallet
                    product with incorporated security and privacy will be
                    issued out.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Our crypto wallet development services consist of :
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    1. Desktop Crypto Wallet Development <br />
                    2. Android Crypto Wallet Development <br />
                    3. iOS Crypto Wallet Development <br />
                    4. Private and Public Keys Management <br />
                    5. Multi-cryptocurrency Crypto Wallet Development <br />
                    6. Electrum Wallet ( Light Wallet ) <br />
                    7. TrustWallet Integration <br />
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Knackroot crypto wallet development offering includes among
                    other the following feature set :
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    1. Two-factor authentication to ensure security <br />
                    2. Generation of a new public key for every new transaction
                    <br />
                    3. Rejection of duplicate payments to circumvent chargebacks
                    <br />
                    4. Timely session logoutt <br />
                    5. QR code scanner <br />
                    6. Push notifications for cryptocurrencies price changes
                    <br />
                    7. Paper crypto wallet development <br />
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Knackroot team follows agile software development
                    methodology to rapidly deliver superb blockchain code by
                    applying the proprietary framework that includes business
                    requirements analysis, design, development, and blockchain
                    code deployment.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-controls="collapseSeven"
                >
                  Trading Bots
                </button>
              </h3>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray">
                    Cryptocurrencies are known for being incredibly volatile,
                    with prices fluctuating dramatically even in the space of
                    minutes. Investors also have the opportunity to take part in
                    cryptocurrency trading around the world and at any hour of
                    the day. Combined, these factors limit the effectiveness of
                    human cryptocurrency trading in several ways.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Fortunately for many investors, there are solutions to these
                    issues. One of the primary solutions is bots, or automated
                    tools that conduct trades and execute transactions on behalf
                    of human investors.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    There are many varieties of cryptocurrency bots. One of the
                    most popular types is the Arbitrage Bot. Arbitrage bots are
                    tools that examine prices across exchanges and make trades
                    in order to take advantage of discrepancies. Because the
                    price of a cryptocurrency like bitcoin tends to vary
                    somewhat from exchange to exchange, bots that can move fast
                    enough can beat exchanges that are delayed in updating their
                    prices
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Other types of bots use historical price data to test out
                    trading strategies, theoretically offering investors a leg
                    up. Still other bots are programmed to execute trades at
                    particular signals such as price or trading volume.
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingEight">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  STO
                </button>
              </h3>
              <div
                id="collapseEight"
                className="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <h3 className="small-text txt-gray">
                    Security Token Offering (STO) is a type of fundraising that
                    is performed with a company offering tokenized securities.
                    Essentially, Security Tokens provide investors with an array
                    of financial rights that are coded in smart contracts, with
                    underlying tokens traded on the blockchain. Generally,
                    undertaking an STO venture is a daring move. It incorporates
                    creation and distribution of digital assets on a secure
                    internet hub. The success of the company directly dictates
                    the value of the asset which is traded on exchanges.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    STOs have regularly been linked with often controversial
                    ICOs, however, STOs are always backed by some form of
                    tangible asset which prevents investors from falling prey to
                    fraudulent business activities. Due to the fact that STOs
                    are classed as securities, they are also subject to
                    securities regulations for the country they are launched in
                    and for the countries of their investors.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Knackroot is a leading STO development company enabling
                    entrepreneurs and companies to produce sustainable business
                    success, by providing value-driven blockchain services and
                    solutions. We specialize in delivering STO launch services,
                    built upon next-generation disruptive technologies. Our team
                    of agile blockchain development experts provides smart
                    contract development services in Solidity, Stellar, Quorum,
                    Hyperledger Fabric, Lysk and EOS.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Our cutting-edge development processes have been carefully
                    devised to handle each stage robustly and with full
                    accordance to your specifications.
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    <ol>
                      <li className="mb-3">
                        Generate list of business requirements essential for
                        comprehensive analysis and token model creation
                      </li>
                      <li className="mb-3">
                        Preparing a conceptual, logical and sequential
                        blockchain-architecture design based on features set
                      </li>
                      <li className="mb-3">
                        Development of Token on the desired network according to
                        the tokenization model
                      </li>
                      <li className="mb-3">
                        Development of the technical platform for STO with
                        KYC/AML
                      </li>
                      <li className="mb-3">
                        Once the token is audited, assessed, and verified for
                        successful launch, project will be migrated to
                        production environment
                      </li>
                    </ol>
                  </h3>
                  <h3 className="small-text txt-gray mt-3">
                    Knackroot offers full cycle STO Launch Services to help
                    companies yield the highest return on investment.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
