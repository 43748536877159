import { useEffect } from "react";
import {
  Badge2,
  Goodfirm,
  Icon_Msg,
  Icon_Phone,
  Icon_Location,
  Icon_FB,
  Icon_GitHub,
  Icon_LinkedIn,
  Icon_Twitter,
  WhiteLogo,
  SERVICE_ROUTE,
} from "../config/constants";
import "../styles/styles.css";
import "../styles/media.css";
import { Link, NavLink } from "react-router-dom";
import AOS from "aos";
import { ReactComponent as Rating } from "../assets/images/star-rating.svg";

const Footer = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const {
    SMART_CONTRACT,
    PRIVATE_BLOCKCHAIN,
    PUBLIC_BLOCKCHAIN,
    DEFI,
    CRYPTO_EXCHANGE,
    CRYPTO_WALLET,
    MINING_SOFTWARE,
    NFT_MARKETPLACE,
    STO,
    TRADING_BOTS,
    WEB3_DEVELOPMENT,
    WEB3_MIGRATION,
  } = SERVICE_ROUTE;

  const ServiceItem = [
    {
      heading: "Smart Contract Development",
      route: `/services/${SMART_CONTRACT}`,
    },
    {
      heading: "Private Blockchain Development",
      route: `/services/${PRIVATE_BLOCKCHAIN}`,
    },
    {
      heading: "Public Blockchain Development",
      route: `/services/${PUBLIC_BLOCKCHAIN}`,
    },
    {
      heading: "NFT Marketplace Development",
      route: `/services/${NFT_MARKETPLACE}`,
    },
    {
      heading: "Web3 FullStack Development",
      route: `/services/${WEB3_DEVELOPMENT}`,
    },
    {
      heading: "Crypto Exchange Development",
      route: `/services/${CRYPTO_EXCHANGE}`,
    },
    {
      heading: "Mining Software",
      route: `/services/${MINING_SOFTWARE}`,
    },

    {
      heading: "DeFi Development",
      route: `/services/${DEFI}`,
    },
    {
      heading: "Web2 to Web3 Migration",
      route: `/services/${WEB3_MIGRATION}`,
    },
    {
      heading: "Trading Bots Development",
      route: `/services/${TRADING_BOTS}`,
    },
    {
      heading: "STO",
      route: `/services/${STO}`,
    },
    {
      heading: "Crypto Wallets Development",
      route: `/services/${CRYPTO_WALLET}`,
    },
  ];

  return (
    <div>
      <div className="footer-main" data-aos="fade">
        <div className="img pb-5">
          <img src={WhiteLogo} alt="WhiteLogo" />
        </div>
        <div className="footer-cover txt-white">
          <div className="contacts">
            <div className="contact-cover">
              <div className="icons pe-3">
                <img src={Icon_Location} alt="Icon_Location" />
              </div>
              <div
                className="small-text pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="click to copy"
              >
                Connekt, 13th Floor, Gala Empire, Opp. T.V. Tower, Drive In Rd,
                Ahmedabad, Gujarat 380052
              </div>
            </div>
            <div className="contact-cover">
              <div className="icons pe-3">
                <img src={Icon_Phone} alt="Icon_Phone" />
              </div>
              <div
                className="small-text pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="click to copy"
              >
                +91-9898777397
              </div>
            </div>
            <div className="contact-cover">
              <div className="icons pe-3">
                <img src={Icon_Msg} alt="Icon_Msg" />
              </div>
              <div
                className="small-text pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="click to copy"
              >
                info@knackroot.com
              </div>
            </div>
            <div className="social">
              <h3 className="subtitle">Social</h3>
              <p>
                <Link
                  target="_blank"
                  to="https://twitter.com/knackroot"
                  className="pe-2"
                  rel="noreferrer"
                >
                  <img src={Icon_Twitter} alt="Icon_Twitter" />
                </Link>
                <Link
                  target="_blank"
                  to="https://www.facebook.com/Knackroot-TechnoLabs-LLP-740804566278801"
                  className="pe-2"
                >
                  <img src={Icon_FB} alt="Icon_FB" />
                </Link>
                <Link
                  target="_blank"
                  to="https://in.linkedin.com/company/knackroot-technolab"
                  className="pe-2"
                >
                  <img src={Icon_LinkedIn} alt="Icon_LinkedIn" />
                </Link>
                <Link
                  target="_blank"
                  to="https://github.com/knackroot-technolabs-llp"
                  className="pe-2"
                >
                  <img src={Icon_GitHub} alt="Icon_GitHub" />
                </Link>
              </p>
            </div>
            <Link
              className="badge-main show-badge"
              target="_blank"
              to="https://www.goodfirms.co/company/knackroot-technolabs-llp"
            >
              <div className="badge-top mb-1">
                <span className="badge-txt"> EXCELLENT </span>
                <span>
                  <Rating />
                </span>
              </div>
              <div className="badge-bottom">
                <span className="fw-600">
                  5 <span className="sm-txt"> out of </span> 5
                  <span className="sm-txt"> on </span> 7
                  <span className="sm-txt"> reviews </span>
                </span>
                <span>
                  <img className="img-gfirm" src={Goodfirm} alt="GoodfirmImg" />
                </span>
              </div>
            </Link>
            <Link
              className="show-badge"
              to={"/blog/knackroot-review-by-goodfirms"}
            >
              <img className="img-badge-1" src={Badge2} alt="Badge2Img" />
            </Link>
          </div>
          <div className="footer-links">
            <div className="d-flex">
              <div className="link">
                <h3 className="footer-title">Links</h3>
                <NavLink to="/" className="small-text pb-2" rel="noreferrer">
                  Home
                </NavLink>
                <NavLink
                  to="/about-us"
                  className="small-text pb-2"
                  rel="noreferrer"
                >
                  About
                </NavLink>
                <NavLink
                  to="/blogs"
                  className="small-text pb-2"
                  rel="noreferrer"
                >
                  Blog
                </NavLink>
                <NavLink
                  to="/contact-us"
                  className="small-text pb-2"
                  rel="noreferrer"
                >
                  Contact
                </NavLink>
                <NavLink to="/careers" className="small-text" rel="noreferrer">
                  Career
                </NavLink>
              </div>
              <div className="service">
                <h3 className="footer-title">Service</h3>
                <div className="serv-link">
                  {ServiceItem.map(({ heading, route }, i) => (
                    <NavLink
                      className="small-text pb-2"
                      to={route}
                      rel="noreferrer"
                      key={i}
                    >
                      {heading}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
            <div className="footer-badge-cover">
              <Link
                className="badge-main hide-badge"
                target="_blank"
                to="https://www.goodfirms.co/company/knackroot-technolabs-llp"
                rel="noreferrer"
              >
                <div className="badge-top mb-1">
                  <span className="badge-txt"> EXCELLENT </span>
                  <span>
                    <Rating />
                  </span>
                </div>
                <div className="badge-bottom">
                  <span className="fw-600">
                    5 <span className="sm-txt"> out of </span> 5
                    <span className="sm-txt"> on </span> 7
                    <span className="sm-txt"> reviews </span>
                  </span>
                  <span>
                    <img
                      className="img-gfirm"
                      src={Goodfirm}
                      alt="GoodfirmImg"
                    />
                  </span>
                </div>
              </Link>
              <Link
                className="hide-badge"
                to={"/blog/knackroot-review-by-goodfirms"}
                rel="noreferrer"
              >
                <img className="img-badge-1" src={Badge2} alt="Badge2Img" />
              </Link>
            </div>
          </div>
        </div>
        <div className="copyright-box">
          <p>
            <span>{new Date().getFullYear()}</span>© All rights reserved by
            Knackroot
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
