import { useEffect } from "react";
import AOS from "aos";

const Approch = () => {
  
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="container-fluid my-5 approch-main" data-aos="fade-up">
      <h3 className="title text-center txt-blue my-3">Our Approach 👀</h3>
      <div className="approch-cover">
        <div
          className="nav-left nav flex-column nav-pills me-3"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <div
            className="nav-link approch-text mt-2 pb-4 active"
            id="v-pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-home"
            type="button"
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
          >
            <span> Advisory </span>
            <span>
              <i className="fas fa-thin fa-arrow-right"></i>
            </span>
          </div>
          <div
            className="nav-link approch-text mt-4 pb-4"
            id="v-pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-profile"
            type="button"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected="false"
          >
            <span> Prototype</span>
            <span>
              {" "}
              <i className="fas fa-thin fa-arrow-right-long"></i>
            </span>
          </div>
          <div
            className="nav-link approch-text mt-4 pb-4"
            id="v-pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-settings"
            type="button"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected="false"
          >
            <span> Production</span>
            <span>
              {" "}
              <i className="fas fa-thin fa-arrow-right-long"></i>
            </span>
          </div>
          <div
            className="nav-link approch-text mt-4 pb-4"
            id="v-pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-messages"
            type="button"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected="false"
          >
            <span> Support</span>
            <span>
              {" "}
              <i className="fas fa-thin fa-arrow-right-long"></i>
            </span>
          </div>
        </div>
        <div className="nav-right tab-content" id="v-pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <h4 className="small-text txt-gray">
              We work with you to learn about your project and provide
              independent strategic advice on whether blockchain is suitable and
              adds genuine technical and business value to the problem you are
              trying to solve. <br />
              <br />
              We assess your requirements to provide you with an impartial
              recommendation to approach and implement your blockchain project
              and then advise which of the available blockchain technologies is
              best suited for your application.
            </h4>
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-profile"
            role="tabpanel"
            aria-labelledby="v-pills-profile-tab"
          >
            <h4 className="small-text txt-gray">
              The prototype is for you to test against your requirements and
              technical needs. Our solution architects, developers and designers
              work together to prove that the concepts for the proposed
              blockchain application are viable. <br />
              <br />
              We create a working prototype based on the minimum requirements of
              the product, for you to test and to give feedback on.
            </h4>
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-messages"
            role="tabpanel"
            aria-labelledby="v-pills-messages-tab"
          >
            <h4 className="small-text txt-gray">
              Our developers build your project and test it at every stage.
              After the build is complete, we coordinate with your team and
              support you implementing the project. <br />
              <br />
              We move into production, and then your project is live.
            </h4>
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-settings"
            role="tabpanel"
            aria-labelledby="v-pills-settings-tab"
          >
            <h4 className="small-text txt-gray">
              We provide you with ongoing support from the day we start a
              conversation with you. <br />
              <br />
              After the delivery and deployment of your project, we continue to
              work with you to ensure its smooth operation and advise on
              technical enhancements if required.
            </h4>
          </div>
        </div>
      </div>
      <div className="main-acc-cover">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h3 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Advisory
              </button>
            </h3>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body txt-gray small-text">
                We work with you to learn about your project and provide
                independent strategic advice on whether blockchain is suitable
                and adds genuine technical and business value to the problem you
                are trying to solve. <br />
                <br />
                We assess your requirements to provide you with an impartial
                recommendation to approach and implement your blockchain project
                and then advise which of the available blockchain technologies
                is best suited for your application.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Prototype
              </button>
            </h3>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body txt-gray small-text">
                The prototype is for you to test against your requirements and
                technical needs. Our solution architects, developers and
                designers work together to prove that the concepts for the
                proposed blockchain application are viable. <br />
                <br />
                We create a working prototype based on the minimum requirements
                of the product, for you to test and to give feedback on.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Production
              </button>
            </h3>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body txt-gray small-text">
                Our developers build your project and test it at every stage.
                After the build is complete, we coordinate with your team and
                support you implementing the project. <br />
                <br />
                We move into production, and then your project is live.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Support
              </button>
            </h3>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body txt-gray small-text">
                We provide you with ongoing support from the day we start a
                conversation with you. <br />
                <br />
                After the delivery and deployment of your project, we continue
                to work with you to ensure its smooth operation and advise on
                technical enhancements if required.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approch;
