import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import {
  MainLogo,
  MenuBurger,
  SERVICE_ROUTE,
  INDUSTRY_ROUTE,
  IconCryptoExc,
  IconCryptoWal,
  IconMiningSoft,
  IconPrivateBloc,
  IconPublicBloc,
  IconSTO,
  IconSmartCon,
  IconTradeBot,
  Img_Insurance,
  Img_Healthcare,
  Img_Agri,
  Img_Aero,
  Img_Retail,
  Img_Hitech,
  Img_EnergyCom,
  Img_Finance,
  SolIcon_ERC20,
  SolIcon_BridgSol,
  SolIcon_DAO,
  SolIcon_LSS,
  SolIcon_NFT,
  SolIcon_SmartSwap,
  SolIcon_ZKR,
  SolIcon_Merkle,
  SolIcon_Cosmos,
  SolIcon_BattelGame,
  SolIcon_Dweb,
  SolIcon_MWS,
  SolIcon_TLLS,
  solIcon_Stake,
  solIcon_TVS,
} from "../config/constants";
import "../styles/Navbar.css";
import "../styles/styles.css";
import "../styles/media.css";
import NavButton from "./NavButton";

const Navbar = () => {
  const {
    SMART_CONTRACT,
    PRIVATE_BLOCKCHAIN,
    PUBLIC_BLOCKCHAIN,
    DEFI,
    CRYPTO_EXCHANGE,
    CRYPTO_WALLET,
    MINING_SOFTWARE,
    NFT_MARKETPLACE,
    STO,
    TRADING_BOTS,
    WEB3_DEVELOPMENT,
    WEB3_MIGRATION,
  } = SERVICE_ROUTE;

  const {
    AGRICULTURE,
    AEROSPACE,
    ENERGY,
    FINANCIAL,
    HEALTHCARE,
    INSURANCE,
    MANUFACTURING,
    RETAIL,
  } = INDUSTRY_ROUTE;

  const solItem = [
    {
      img: SolIcon_ERC20,
      title: "Standard and Custom ERC20 Token",
      route: "/",
    },
    {
      img: solIcon_Stake,
      title: "Staking Solutions",
      route: "/",
    },
    {
      img: SolIcon_NFT,
      title: "NFT Marketplace",
      route: "/",
    },
    {
      img: SolIcon_SmartSwap,
      title: "Smart Swap Solution",
      route: "/",
    },
    {
      img: SolIcon_BattelGame,
      title: "P2E & Battle Gaming Platform",
      route: "/",
    },
    {
      img: SolIcon_BridgSol,
      title: "Bridging Solution",
      route: "/",
    },
    {
      img: SolIcon_MWS,
      title: "Multi Wallet Server",
      route: "/",
    },
    {
      img: solIcon_TVS,
      title: "Token Vesting Solution",
      route: "/",
    },
    {
      img: SolIcon_TLLS,
      title: "Token & Liquidity Locking Solution",
      route: "/",
    },
    {
      img: SolIcon_Merkle,
      title: "Launchpad & Merkle Airdrop",
      route: "/",
    },
    {
      img: SolIcon_Cosmos,
      title: "Custom Blockchain Development using Cosmos SDK",
      route: "/",
    },
    {
      img: SolIcon_Dweb,
      title: "Blockchain based Domain Names",
      route: "/",
    },
    {
      img: SolIcon_DAO,
      title: "DAO",
      route: "/",
    },
    {
      img: SolIcon_LSS,
      title: "Layer2 Scaling Solutions",
      route: "/",
    },
    {
      img: SolIcon_ZKR,
      title: "Zero Knowledge Rollups",
      route: "/",
    },
  ];

  const servItem = [
    {
      img: IconSmartCon,
      heading: "Smart Contract Development",
      description:
        "Smart contracts are self-executing contracts that are programmed to automatically execute . . .",
      route: `/services/${SMART_CONTRACT}`,
    },
    {
      img: IconPrivateBloc,
      heading: "Private Blockchain Development",
      description:
        "Private blockchain development is the process of creating a secure and decentralized ledger . . .",
      route: `/services/${PRIVATE_BLOCKCHAIN}`,
    },
    {
      img: IconPublicBloc,
      heading: "Public Blockchain Development",
      description:
        "Public blockchains are open networks that allow anyone to participate in the network . . .",
      route: `/services/${PUBLIC_BLOCKCHAIN}`,
    },
    {
      img: IconSmartCon,
      heading: "DeFi Development",
      description:
        "Lorem ipsum dolor sit , adipisicing elit. Sequi nobis fugit ipsum neque dignissimos.",
      route: `/services/${DEFI}`,
    },
    {
      img: IconPrivateBloc,
      heading: "NFT Marketplace Development",
      description:
        "Lorem ipsum dolor sit , adipisicing elit. Sequi nobis fugit ipsum neque dignissimos.",
      route: `/services/${NFT_MARKETPLACE}`,
    },
    {
      img: IconPublicBloc,
      heading: "Web3 FullStack Development",
      description:
        "Lorem ipsum dolor sit , adipisicing elit. Sequi nobis fugit ipsum neque dignissimos.",
      route: `/services/${WEB3_DEVELOPMENT}`,
    },
    {
      img: IconMiningSoft,
      heading: "Mining Software",
      description:
        "Cryptocurrency mining, or cryptomining, is a process in which transactions for various . . .",
      route: `/services/${MINING_SOFTWARE}`,
    },
    {
      img: IconCryptoExc,
      heading: "Crypto Exchange Development",
      description:
        "Cryptocurrency trading has become a popular investment option for individuals . . .",
      route: `/services/${CRYPTO_EXCHANGE}`,
    },
    {
      img: IconSmartCon,
      heading: "Web2 to Web3 Migration",
      description:
        "Lorem ipsum dolor sit , adipisicing elit. Sequi nobis fugit ipsum neque dignissimos.",
      route: `/services/${WEB3_MIGRATION}`,
    },
    {
      img: IconTradeBot,
      heading: "Trading Bots Development",
      description:
        "Trading bots have become an essential tool for traders who want to automate their strategies . . .",
      route: `/services/${TRADING_BOTS}`,
    },
    {
      img: IconSTO,
      heading: "STO",
      description:
        "Security Token Offering (STO) is a type of fundraising that is performed with a company . . .",
      route: `/services/${STO}`,
    },
    {
      img: IconCryptoWal,
      heading: "Crypto Wallets Development",
      description:
        "Cryptocurrency wallets or crypto wallets are digitally secured systems that allow receiving . . .",
      route: `/services/${CRYPTO_WALLET}`,
    },
  ];

  const companyItem = [
    {
      img: "",
      title: "About Us",
      route: "/about-us",
    },
    {
      img: "",
      title: "Careers",
      route: "/careers",
    },
    {
      img: "",
      title: "Blog",
      route: "/blogs",
    },
  ];

  const industryItem = [
    {
      img: Img_Insurance,
      title: "Insurance",
      route: `/usecases/${INSURANCE}`,
    },
    {
      img: Img_Healthcare,
      title: "Healthcare and Life Sciences",
      route: `/usecases/${HEALTHCARE}`,
    },
    {
      img: Img_Finance,
      title: "Financial Services",
      route: `/usecases/${FINANCIAL}`,
    },
    {
      img: Img_Agri,
      title: "Agri-Business",
      route: `/usecases/${AGRICULTURE}`,
    },
    {
      img: Img_Aero,
      title: "Aero",
      route: `/usecases/${AEROSPACE}`,
    },
    {
      img: Img_Retail,
      title: "Retail and CPG",
      route: `/usecases/${RETAIL}`,
    },
    {
      img: Img_Hitech,
      title: "Manufacturing & Hi Tech",
      route: `/usecases/${MANUFACTURING}`,
    },
    {
      img: Img_EnergyCom,
      title: "Energy, Communication and Services",
      route: `/usecases/${ENERGY}`,
    },
  ];

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light">
        <div className="container-fluid nav-fix">
          <Link className="navbar-brand" to="/">
            <img className="main-logo" src={MainLogo} alt="MainLogo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img
              className="navbar-toggler-icon"
              src={MenuBurger}
              alt="MenuBurger"
            />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <NavLink
                  className="nav-a-link nav-link"
                  aria-current="page"
                  to="/"
                  rel="noreferrer"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-a-link dropdown-toggle"
                  href="/#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Solutions
                </a>
                <ul
                  className="dropdown-menu solItem drop-cover"
                  aria-labelledby="navbarDropdown"
                >
                  {solItem.map(({ img, route, title }, i) => (
                    <li key={i}>
                      <NavLink
                        className="drop-item d-flex"
                        to={route}
                        rel="noreferrer"
                      >
                        <div className="drop-item-img">
                          <img src={img} alt={title} />
                        </div>
                        <div className="drop-item-title d-flex">
                          <h3 className="small-text-1 txt-blue">{title}</h3>
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-a-link dropdown-toggle"
                  href="/#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </a>
                <ul
                  className="dropdown-menu servItem drop-cover"
                  aria-labelledby="navbarDropdown"
                >
                  {servItem.map(({ heading, route, img }, i) => (
                    <li key={i}>
                      <NavLink
                        className="drop-item d-flex"
                        to={route}
                        rel="noreferrer"
                      >
                        <div className="drop-item-img">
                          <img src={img} alt={heading} />
                        </div>
                        <div className="drop-item-title d-flex">
                          <h3 className="small-text-1 txt-blue">{heading}</h3>
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-a-link dropdown-toggle"
                  href="/#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Industries
                </a>
                <ul
                  className="dropdown-menu industryItem drop-cover"
                  aria-labelledby="navbarDropdown"
                >
                  {industryItem.map(({ img, route, title }, i) => (
                    <li key={i}>
                      <NavLink
                        className="drop-item d-flex"
                        to={route}
                        rel="noreferrer"
                      >
                        <div className="drop-item-img">
                          <img className="industryImgs" src={img} alt={title} />
                        </div>
                        <div className="drop-item-title d-flex">
                          <h3 className="small-text-1 txt-blue">{title}</h3>
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-a-link nav-link"
                  aria-current="page"
                  to="/#testimonials"
                  rel="noreferrer"
                >
                  Testimonials
                </Link>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-a-link dropdown-toggle"
                  href="/#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Company
                </a>
                <ul
                  className="dropdown-menu companyItem drop-cover"
                  aria-labelledby="navbarDropdown"
                >
                  {companyItem.map(({ img, route, title }, i) => (
                    <li key={i}>
                      <NavLink
                        className="drop-item d-flex"
                        to={route}
                        rel="noreferrer"
                      >
                        <div className="drop-item-title d-flex">
                          <h3 className="small-text-1 txt-blue">{title}</h3>
                        </div>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
            <ul className="navbar-nav me-5 ms-auto">
              <NavButton title={"Contact Us"} route={"/contact-us"} />
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
