import React from "react";
import { useParams } from "react-router-dom";
import { POST_ROUTE } from "../../config/constants";
import Post1 from "./Post1";
import Post2 from "./Post2";
import Post3 from "./Post3";
import Post4 from "./Post4";
import Post5 from "./Post5";
import Post6 from "./Post6";
import Post7 from "./Post7";
import Post8 from "./Post8";
import Post9 from "./Post9";
import Post10 from "./Post10";
import Post11 from "./Post11";
import Post12 from "./Post12";
import Post13 from "./Post13";
import Post14 from "./Post14";
import Post15 from "./Post15";
import Post16 from "./Post16";
import Post17 from "./Post17";
import Post18 from "./Post18";
import Post19 from "./Post19";
import Post20 from "./Post20";
import Post21 from "./Post21";
import Post22 from "./Post22";

const BlogPost = () => {
  const { post } = useParams();
  const {
    POST_1,
    POST_2,
    POST_3,
    POST_4,
    POST_5,
    POST_6,
    POST_7,
    POST_8,
    POST_9,
    POST_10,
    POST_11,
    POST_12,
    POST_13,
    POST_14,
    POST_15,
    POST_16,
    POST_17,
    POST_18,
    POST_19,
    POST_20,
    POST_21,
    POST_22,
  } = POST_ROUTE;

  switch (post) {
    case POST_1:
      return <Post1 />;
    case POST_2:
      return <Post2 />;
    case POST_3:
      return <Post3 />;
    case POST_4:
      return <Post4 />;
    case POST_5:
      return <Post5 />;
    case POST_6:
      return <Post6 />;
    case POST_7:
      return <Post7 />;
    case POST_8:
      return <Post8 />;
    case POST_9:
      return <Post9 />;
    case POST_10:
      return <Post10 />;
    case POST_11:
      return <Post11 />;
    case POST_12:
      return <Post12 />;
    case POST_13:
      return <Post13 />;
    case POST_14:
      return <Post14 />;
    case POST_15:
      return <Post15 />;
    case POST_16:
      return <Post16 />;
    case POST_17:
      return <Post17 />;
    case POST_18:
      return <Post18 />;
    case POST_19:
      return <Post19 />;
    case POST_20:
      return <Post20 />;
    case POST_21:
      return <Post21 />;
    case POST_22:
      return <Post22 />;
    default:
      return <></>;
  }
};

export default BlogPost;
