import { useEffect } from "react";
import {
  Img_About_0,
  Img_About_1,
  Img_About_2,
  Img_About_3,
  Img_About_4,
  WCU_1,
  WCU_2,
  WCU_3,
  WCU_4,
} from "../config/constants";
import "../styles/styles.css";
import "../styles/media.css";
import AOS from "aos";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const About = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname, "", "About Page");
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Knackroot TechnoLabs is an IT consulting and solution provider of next-gen business collaboration with customers across the world. With tremendous experience, comprehensive intelligence system across diverse industries and domains, we work with customers to turn them into most successful and high-performance organizations."
        />
      </Helmet>
      <div className="main-sec-about" data-aos="fade">
        <div className="aboutpg-left w-50">
          <div className="aboutpg-cover-main ml-auto">
            <h3 className="title txt-blue">About us</h3>
            <h4 className="small-text txt-gray">
              Looking for experts who can help you construct your business idea
              into a real blockchain solution ? We can help you !
            </h4>
          </div>
        </div>
        <div className="aboutpg-right w-50">
          <img
            className="aboutpg-main-img aboutpg-img"
            src={Img_About_0}
            alt="Img_About"
          />
        </div>
      </div>

      {/* <!-- About Section - 1  --> */}

      <div
        className="sub-sec-about d-flex align-items-center"
        data-aos="fade-up"
      >
        <div className="aboutpg-left w-50 d-flex mr-10">
          <img className="ml-auto aboutpg-img" src={Img_About_1} alt="Img_About_1" />
        </div>
        <div className="aboutpg-right w-50 d-flex">
          <div className="aboutpg-cover mr-auto ml-30">
            <h3 className="subtitle txt-blue">
              We are here to shape your ideas with our experience
            </h3>
            <h4 className="small-text txt-gray">
              Knackroot TechnoLabs is an IT consulting and solution provider of
              next-gen business collaborate with customers across the world.
              With tremendous experience, comprehensive intelligence system
              across diverse industries and domains, we work with customers to
              turn them into most successful and high-performance organizations.
            </h4>
          </div>
        </div>
      </div>

      {/* <!-- About Section - 2  --> */}

      <div
        className="sub-sec-about bg-light-cyan d-flex align-items-center sub-sec-about-reverse"
        data-aos="fade-up"
      >
        <div className="aboutpg-left w-50 d-flex">
          <div className="aboutpg-cover ml-auto mr-30">
            <h3 className="title txt-blue">Established</h3>
            <h4 className="small-text txt-gray">
              Established in 2017, Knackroot TechnoLabs is headquartered at
              Ahmedabad, India with presence in Canada, Australia and the USA.
              The commitment to quality and the spirit to innovate has made us
              serve over 20+ clients across 7 countries with 100% client
              retention.
            </h4>
          </div>
        </div>
        <div className="aboutpg-right w-50 d-flex ml-10">
          <img className="mr-auto aboutpg-img" src={Img_About_2} alt="Img_About_2" />
        </div>
      </div>

      {/* <!-- About Section - 3  --> */}

      <div
        className="sub-sec-about d-flex align-items-center"
        data-aos="fade-up"
      >
        <div className="aboutpg-left w-50 d-flex mr-10">
          <img className="ml-auto aboutpg-img" src={Img_About_3} alt="Img_About_3" />
        </div>
        <div className="aboutpg-right w-50 d-flex">
          <div className="aboutpg-cover mr-auto ml-30">
            <h3 className="title txt-blue">Services Offerings</h3>
            <h4 className="small-text txt-gray">
              Knackroot TechnoLabs continues to fuel technological innovations
              in multiple verticals. The company’s service offerings include
              Blockchain Consulting, Machine Learning Consulting, Data Science
              Consulting, Digital Transformation and Connected IoT Solutions
              across various Cloud Platforms, including AWS and Azure.
            </h4>
          </div>
        </div>
      </div>

      {/* <!-- About Section - 4  --> */}

      <div
        className="sub-sec-about bg-light-cyan d-flex align-items-center sub-sec-about-reverse"
        data-aos="fade-up"
      >
        <div className="aboutpg-left w-50 d-flex">
          <div className="aboutpg-cover ml-auto mr-30">
            <h3 className="title txt-blue">Our Objective</h3>
            <h4 className="small-text txt-gray">
              Knackroot TechnoLabs’ IT and business services help clients who
              comprise the current world economy, right from small-medium
              businesses to the most successful global Organizations. Our
              objective is to deliver robust, scalable, viable and quality
              solutions while adhering to strict deadline.
            </h4>
          </div>
        </div>
        <div className="aboutpg-right w-50 d-flex ml-10">
          <img className="mr-auto aboutpg-img" src={Img_About_4} alt="Img_About_4" />
        </div>
      </div>

      {/* <!-- Why Choose Us Section  --> */}

      <div className="container-fluid my-5 wcu-main">
        <h3 className="title text-center txt-blue mt-5" data-aos="fade-up">
          Why Choose Us 😎
        </h3>
        <h3 className="subtitle txt-blue text-center py-3" data-aos="fade-up">
          There are many companies but why
          <span className="txt-cyan"> choose us </span>
        </h3>
        <div className="wcu-cover" data-aos="fade-up">
          <div className="tab-cover">
            <div
              className="nav-left nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <div
                className="nav-link tab-text mt-2 active"
                id="v-pills-exp-team-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-exp-team"
                type="button"
                role="tab"
                aria-controls="v-pills-exp-team"
                aria-selected="true"
              >
                <span> Expert Team </span>
                <span>
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>
              <div
                className="nav-link tab-text mt-4"
                id="v-pills-ideas-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-ideas"
                type="button"
                role="tab"
                aria-controls="v-pills-ideas"
                aria-selected="false"
              >
                <span> Best Leadership Ideas </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>
              <div
                className="nav-link tab-text mt-4"
                id="v-pills-learn-growth-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-learn-growth"
                type="button"
                role="tab"
                aria-controls="v-pills-learn-growth"
                aria-selected="false"
              >
                <span> Learning and Growth </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>
              <div
                className="nav-link tab-text mt-4"
                id="v-pills-culture-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-culture"
                type="button"
                role="tab"
                aria-controls="v-pills-culture"
                aria-selected="false"
              >
                <span> Culture </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>
              <div
                className="nav-link tab-text mt-4"
                id="v-pills-training-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-training"
                type="button"
                role="tab"
                aria-controls="v-pills-training"
                aria-selected="false"
              >
                <span> Training & Internship </span>
                <span>
                  {" "}
                  <i className="fas fa-thin fa-arrow-right-long"></i>
                </span>
              </div>
            </div>
          </div>
          <div
            className="nav-right tab-content d-flex tab-content-cover"
            id="v-pills-tabContent"
          >
            <div
              className="tab-pane fade show active mx-auto my-auto"
              id="v-pills-exp-team"
              role="tabpanel"
              aria-labelledby="v-pills-exp-team-tab"
            >
              <img src={Img_About_0} alt="Img_About_0" />
              <h3 className="small-text-1 text-center txt-pink mt-5">
                The argument in favorite of using filler to text goes some thing
                like this top
              </h3>
            </div>
            <div
              className="tab-pane fade mx-auto my-auto"
              id="v-pills-ideas"
              role="tabpanel"
              aria-labelledby="v-pills-ideas-tab"
            >
              <img src={WCU_1} alt="WCU_1" />
              <h3 className="small-text-1 text-center txt-pink mt-5">
                The argument in favorite of using filler to text goes some thing
                like this top
              </h3>
            </div>
            <div
              className="tab-pane fade mx-auto my-auto"
              id="v-pills-culture"
              role="tabpanel"
              aria-labelledby="v-pills-culture-tab"
            >
              <img src={WCU_3} alt="WCU_3" />
              <h3 className="small-text-1 text-center txt-pink mt-5">
                Knackroot nurtures a performance-based culture where everyone
              </h3>
            </div>
            <div
              className="tab-pane fade mx-auto my-auto"
              id="v-pills-learn-growth"
              role="tabpanel"
              aria-labelledby="v-pills-learn-growth-tab"
            >
              <img src={WCU_2} alt="WCU_2" />
              <h3 className="small-text-1 text-center txt-pink mt-5">
                We are continuously striving to create new development
                opportunities
              </h3>
            </div>
            <div
              className="tab-pane fade mx-auto my-auto"
              id="v-pills-training"
              role="tabpanel"
              aria-labelledby="v-pills-training-tab"
            >
              <img src={WCU_4} alt="WCU_4" />
              <h3 className="small-text-1 text-center txt-pink mt-5">
                With our cheerful, friendly and supportive working environment
              </h3>
            </div>
          </div>
        </div>

        <div className="wcu-acc-cover">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Expert Team
                </button>
              </h3>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <img src={Img_About_0} alt="Img_About_0" />
                  <h3 className="small-text-1 text-center txt-pink text-center mt-5">
                    The argument in favorite of using filler to text goes some
                    thing like this top
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Best Leadership Ideas
                </button>
              </h3>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <img src={WCU_1} alt="WCU_1" />
                  <h3 className="small-text-1 text-center txt-pink text-center mt-5">
                    The argument in favorite of using filler to text goes some
                    thing like this top
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Learning and Growth
                </button>
              </h3>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <img src={WCU_3} alt="WCU_3" />
                  <h3 className="small-text-1 text-center txt-pink text-center mt-5">
                    We are continuously striving to create new development
                    opportunities
                  </h3>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Culture
                </button>
              </h3>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <img src={WCU_2} alt="WCU_2" />
                </div>
                <h3 className="small-text-1 text-center txt-pink text-center mt-5">
                  Knackroot nurtures a performance-based culture where everyone
                </h3>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Training & Internships
                </button>
              </h3>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body mt-3">
                  <img src={WCU_4} alt="WCU_4" />
                </div>
                <h3 className="small-text-1 text-center txt-pink text-center mt-5">
                  With our cheerful, friendly and supportive working environment
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
