import React from "react";
import { NavLink } from "react-router-dom";
import { Arrow_WhiteBG } from "../config/constants";
import styles from "../styles/NavButton.module.css";

const NavButton = ({ title, icon, route }) => {
  return (
    <div className={styles.NavButtonContainer}>
      <NavLink className={styles.NavLinkButton} to={route} rel="noreferrer">
        <span className={styles.NavLinkTitle}>{title}</span>
        {icon && (
          <span className={styles.NavLinkIcon}>
            <img src={Arrow_WhiteBG} alt="ArrowIcon" />
          </span>
        )}
      </NavLink>
    </div>
  );
};

export default NavButton;
