// Exports all Images

export { default as MainLogo } from "../assets/images/KnackrootLogo.png";
export { default as WhiteLogo } from "../assets/images/logo-w.svg";
export { default as MenuBurger } from "../assets/images/MenuBurger.svg";
export { default as Group } from "../assets/images/Group.svg";
export { default as Img_About_0 } from "../assets/images/wcu-0.svg";
export { default as Img_About_1 } from "../assets/images/abt-i1.png";
export { default as Img_About_2 } from "../assets/images/abt-i2.png";
export { default as Img_About_3 } from "../assets/images/abt-i3.png";
export { default as Img_About_4 } from "../assets/images/abt-i4.png";
export { default as Arrow_WhiteBG } from "../assets/images/icons/whiteBgArrow.svg";
export { default as Rectangle12 } from "../assets/images/rectangle-12.svg";
export { default as Rectangle16 } from "../assets/images/rectangle-16.svg";
export { default as Rectangle17 } from "../assets/images/rectangle-17.svg";
export { default as Rectangle19 } from "../assets/images/rectangle-19.svg";
export { default as WCU_1 } from "../assets/images/wcu-1.svg";
export { default as WCU_2 } from "../assets/images/wcu-2.svg";
export { default as WCU_3 } from "../assets/images/wcu-3.svg";
export { default as WCU_4 } from "../assets/images/wcu-4.svg";
export { default as Badge2 } from "../assets/images/badge-2.svg";
export { default as Goodfirm } from "../assets/images/goodfirm.svg";
export { default as Icon_Location } from "../assets/images/location.svg";
export { default as Icon_Phone } from "../assets/images/phone.svg";
export { default as Icon_Msg } from "../assets/images/mail.svg";
export { default as Icon_Twitter } from "../assets/images/twitter.svg";
export { default as Icon_FB } from "../assets/images/fb.svg";
export { default as Icon_LinkedIn } from "../assets/images/linkedin.svg";
export { default as Icon_GitHub } from "../assets/images/github.svg";
export { default as IconSmartCon } from "../assets/images/icons/IconSmartCon.svg";
export { default as IconPrivateBloc } from "../assets/images/icons/IconPrivateBloc.svg";
export { default as IconPublicBloc } from "../assets/images/icons/IconPublicBloc.svg";
export { default as IconMiningSoft } from "../assets/images/icons/IconMiningSoft.svg";
export { default as IconCryptoExc } from "../assets/images/icons/IconCryptoExc.svg";
export { default as IconCryptoWal } from "../assets/images/icons/IconCryptoWal.svg";
export { default as IconTradeBot } from "../assets/images/icons/IconTradeBot.svg";
export { default as IconSTO } from "../assets/images/icons/IconSTO.svg";
export { default as IconUsecaseAna } from "../assets/images/icons/IconUsecaseAna.svg";
export { default as IconPlatEval } from "../assets/images/icons/IconPlatEval.svg";
export { default as IconProdDes } from "../assets/images/icons/IconProdDes.svg";
export { default as IconSmartConAud } from "../assets/images/icons/IconSmartConAud.svg";
export { default as IconSecurityAss } from "../assets/images/icons/IconSecurityAss.svg";
export { default as IconPenetTest } from "../assets/images/icons/IconPenetTest.svg";
export { default as Img_Sandip } from "../assets/images/sandip.png";
export { default as Img_Ronak } from "../assets/images/ronak.png";
export { default as Arrow_Right } from "../assets/images/arr-right.svg";
export { default as Arrow_Left } from "../assets/images/arr-left.svg";
export { default as Pink_Arrow_Right } from "../assets/images/pinkArrowRight.svg";
export { default as Pink_Arrow_Left } from "../assets/images/pinkArrowLeft.svg";
export { default as Img_Adam } from "../assets/images/adam.jpg";
export { default as Img_Cristian } from "../assets/images/Christian.jpg";
export { default as Img_Vasudha } from "../assets/images/VasudhaBhushan.jpg";
export { default as Img_Rhyan } from "../assets/images/rhyan.jpg";
export { default as Img_Michael_Calce } from "../assets/images/Michael_Calce.jpg";
export { default as Img_Ben_Dudley } from "../assets/images/Ben_Dudley.jpg";
export { default as Img_MoonMonkey } from "../assets/images/MoonMonkey.png";
export { default as Img_Orbitium } from "../assets/images/Orbitium.png";
export { default as Img_Insurance } from "../assets/images/pc-1.svg";
export { default as Img_Healthcare } from "../assets/images/pc-2.svg";
export { default as Img_Agri } from "../assets/images/pc-3.svg";
export { default as Img_Aero } from "../assets/images/pc-4.jpg";
export { default as Img_Retail } from "../assets/images/pc-5.jpg";
export { default as Img_Hitech } from "../assets/images/pc-6.jpg";
export { default as Img_EnergyCom } from "../assets/images/pc-7.jpg";
export { default as Img_Finance } from "../assets/images/pc-8.jpg";
export { default as Icon_Underline } from "../assets/images/vector-10.svg";
export { default as Img_GoodFirmBlog } from "../assets/images/GoodFirmBlog.png";
export { default as Img_PublicPrivateBlog } from "../assets/images/PublicPrivateBlog.svg";
export { default as Img_UsecaseBlog } from "../assets/images/UsecaseBlog.svg";
export { default as Img_LibraBlog } from "../assets/images/LibraBlog.svg";
export { default as Img_NFTBlog } from "../assets/images/nftBlog.jpg";
export { default as Img_TokenStdBlog } from "../assets/images/tokenStandardBlog.png";
export { default as Img_ZkRollupBlog } from "../assets/images/zkRollupBlog.png";
export { default as Img_SmartContractBlog } from "../assets/images/smartContractBlog.png";
export { default as Img_RealEstateBlog } from "../assets/images/blockchainRealEstateBlog.png";
export { default as Img_DeFiBlog } from "../assets/images/defiBlog.jpg";
export { default as Img_BlcEcomBlog } from "../assets/images/blockchainEcomBlog.png";
export { default as Img_BlcGovernBlog } from "../assets/images/blockchainGovernBlog.png";
export { default as Img_BlcDexBlog } from "../assets/images/blockchainDEXBlog.png";
export { default as Img_BlcLayer2Blog } from "../assets/images/blockchainLayer2Blog.png";
export { default as Img_BlcIoTBlog } from "../assets/images/blockchainIoTBlog.png";
export { default as Img_ConsensusAlgoBlog } from "../assets/images/consensusAlgoBlog.png";
export { default as Img_DaoBlog } from "../assets/images/daoBlog.png";
export { default as Img_HyperledgerBlog } from "../assets/images/hyperledgerBlog.png";
export { default as Img_WorldCoinBlog } from "../assets/images/WorldCoinBlog.png";
export { default as Img_CrossChainBlog } from "../assets/images/crossChainBlog.png";
export { default as Img_PolygonPoSBlog } from "../assets/images/polygonPOSBlog.png";
export { default as Img_DFSBlog } from "../assets/images/dfsBlog.png";
export { default as Img_GoodfirmBlog } from "../assets/images/goodfirmBlogImg.png";
export { default as Img_GoodfirmReview } from "../assets/images/goodfirmReview.png";
export { default as Img_GoodfirmRating } from "../assets/images/overallRating.png";
export { default as Img_Linkedin } from "../assets/images/in.svg";
export { default as Image_Agriculture } from "../assets/images/vectors/Image_Agriculture.png";
export { default as Image_Areo } from "../assets/images/vectors/Image_Areo.png";
export { default as Image_Energy } from "../assets/images/vectors/Image_Energy.png";
export { default as Image_Finance } from "../assets/images/vectors/Image_Finance.png";
export { default as Image_Healthcare } from "../assets/images/vectors/Image_Healthcare.png";
export { default as Image_Insurance } from "../assets/images/vectors/Image_Insurance.png";
export { default as Image_Manufacture } from "../assets/images/vectors/Image_Manufacture.png";
export { default as Image_Retail } from "../assets/images/vectors/Image_Retail.png";
export { default as SolIcon_ERC20 } from "../assets/images/solIcon_ERC20.png";
export { default as SolIcon_SmartSwap } from "../assets/images/solIcon_SmartSwap.png";
export { default as SolIcon_NFT } from "../assets/images/solIcon_NFT.png";
export { default as SolIcon_LSS } from "../assets/images/solIcon_LSS.png";
export { default as SolIcon_ZKR } from "../assets/images/solIcon_ZKR.png";
export { default as SolIcon_DAO } from "../assets/images/solIcon_DAO.png";
export { default as SolIcon_BridgSol } from "../assets/images/solIcon_BridgSol.png";
export { default as SolIcon_Merkle } from "../assets/images/solIcon_Merkle.png";
export { default as SolIcon_Cosmos } from "../assets/images/solIcon_Cosmos.png";
export { default as SolIcon_Dweb } from "../assets/images/solIcon_Dweb.png";
export { default as SolIcon_BattelGame } from "../assets/images/solIcon_BattelGame.png";
export { default as SolIcon_P2EGame } from "../assets/images/solIcon_P2EGame.png";
export { default as SolIcon_MWS } from "../assets/images/solIcon_MWS.png";
export { default as SolIcon_TLLS } from "../assets/images/solIcon_TLLS.png";
export { default as solIcon_Stake } from "../assets/images/solIcon_Stake.png";
export { default as solIcon_TVS } from "../assets/images/solIcon_TVS.png";
export { default as Career_WorkHome } from "../assets/images/careerImg_WorkHome.png";
export { default as Career_GameZone } from "../assets/images/careerImg_GameZone.png";
export { default as Career_TeaCoffee } from "../assets/images/careerImg_TeaCoffee.png";
export { default as Career_Culture } from "../assets/images/careerImg_Culture.png";
export { default as Career_Picnic } from "../assets/images/careerImg_Picnic.png";
export { default as Career_Timing } from "../assets/images/careerImg_Timing.png";
export { default as Career_Awards } from "../assets/images/careerImg_Awards.png";
export { default as Career_CoWorker } from "../assets/images/careerImg_CoWorker.png";

/*  Services Routes  */

export const SERVICE_ROUTE = {
  SMART_CONTRACT: "smart-contract-development",
  PRIVATE_BLOCKCHAIN: "private-blockchain-development",
  PUBLIC_BLOCKCHAIN: "public-blockchain-development",
  DEFI: "defi-development",
  WEB3_DEVELOPMENT: "web3-development",
  NFT_MARKETPLACE: "nft-marketplace-development",
  WEB3_MIGRATION: "web3-migration",
  MINING_SOFTWARE: "mining-software-development",
  CRYPTO_EXCHANGE: "crypto-exchange-development",
  CRYPTO_WALLET: "crypto-wallet-development",
  TRADING_BOTS: "trading-bots-development",
  STO: "sto",
  USECASE_ANALYSIS: "usecase-analysis",
  PLATFORM_EVALUTION: "platform-evalution",
  PRODUCT_DESIGN: "product-design",
  CONTRACT_AUDITS: "smart-contract-audits",
  SECURITY_ASSESSMENT: "security-assessment",
  PENETRATION_TESTING: "penetration-testing",
};

/*  Industry Routes  */

export const INDUSTRY_ROUTE = {
  INSURANCE: "blockchain-in-insurance",
  HEALTHCARE: "blockchain-in-healthcare",
  AGRICULTURE: "blockchain-in-agriculture",
  AEROSPACE: "blockchain-in-aerospace",
  RETAIL: "blockchain-in-retail",
  MANUFACTURING: "blockchain-in-manufacturing",
  ENERGY: "blockchain-in-energy-and-communication",
  FINANCIAL: "blockchain-in-financial",
};

/*  Blog Posts Routes  */

export const POST_ROUTE = {
  POST_1: "knackroot-review-by-goodfirms",
  POST_2: "top-5-usecases-of-blockchain-technology",
  POST_3: "how-facebook-libra-blockchain-work",
  POST_4: "public-vs-private-blockchain-nutshell",
  POST_5: "the-rise-of-nfts",
  POST_6: "understanding-token-standards",
  POST_7: "understanding-zkrollup",
  POST_8: "the-role-of-smart-contract-in-blockchain",
  POST_9: "blockchain-in-real-estate",
  POST_10: "the-future-of-decentralized-finance",
  POST_11: "blockchain-in-ecommerce",
  POST_12: "blockchain-adoptio-in-government-and-public-sector",
  POST_13: "blockchain-decentralized-exchange",
  POST_14: "layer2-solutions-blockchain-technology",
  POST_15: "blockchain-and-internet-of-things",
  POST_16: "consensus-algorithm-in-blockchain",
  POST_17: "understand-decentralized-autonomous-organization",
  POST_18: "exploring-hyperledger-fabric",
  POST_19: "understand-the-world-coin",
  POST_20: "cross-chain-bridges-in-blockchain",
  POST_21: "understand-decentralized-file-storage",
  POST_22: "exploring-polygon-proof-of-stake",
};

export const GOOGLE_ANALYTICS_TRACKING_ID = "UA-266730657-1";
