import React, { useEffect } from "react";
import "../styles/styles.css";
import "../styles/media.css";
import HeroSection from "../components/HeroSection";
import Services from "../components/ServiceList";
import { OurTeam } from "../components/OurTeam";
import Testimonial from "../components/carousels/Testimonial";
import IndustryCarousel from "../components/carousels/IndustryCarousel";
import Approch from "../components/Approch";
import Blog from "../components/blog/Blog";
import SectionAbout from "../components/SectionAbout";
import ReactGA from "react-ga";

const Home = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname, "", "Home Page");
  }, []);

  return (
    <>
      <HeroSection />
      <Services />
      <SectionAbout />
      <OurTeam />
      <Testimonial />
      <IndustryCarousel />
      <Approch />
      <Blog isLatestBlog />
    </>
  );
};

export default Home;
