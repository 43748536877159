import { useEffect } from "react";
import NavButton from "./NavButton";
import AOS from "aos";

const SectionAbout = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="about-main" id="about">
      <div className="about-cover">
        <div className="about-content w-560" data-aos="fade">
          <h3 className="title txt-blue text-center">
            About Us <span>&#9889;</span>
          </h3>
          <h3 className="subtitle txt-blue mb-4 text-center">
            We are Here to Shape Your Ideas With Our
            <span className="txt-cyan"> Experience </span>
          </h3>
        </div>
        <div className="video-cover" data-aos="zoom-in">
            <iframe
              className="video-frame"
              src="https://player.vimeo.com/video/819749761?h=24f46fdc71&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Knackroot"
            ></iframe>
        </div>
        <div
          className="about-details small-text txt-gray text-left mb-4 w-560"
          data-aos="fade"
        >
          <p>
            Knackroot TechnoLabs is an IT consulting and solution provider of
            next-gen business collaboration with customers across the world. With
            tremendous experience, comprehensive intelligence system across
            diverse industries and domains, we work with customers to turn them
            into most successful and high-performance organizations.
          </p>
          <p>
            Established in 2017, Knackroot TechnoLabs is headquartered at
            Ahmedabad, India with presence in Canada, Australia and the USA. The
            commitment to quality and the spirit to innovate has made us serve
            over 20+ clients across 7 countries with 100% client retention.
          </p>
        </div>
        <NavButton route={"/about-us"} title="Read More" icon />
      </div>
    </div>
  );
};

export default SectionAbout;
