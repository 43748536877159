import React from "react";
import "../styles/styles.css";
import NavButton from "../components/NavButton";

const NotFound404 = () => {
  return (
    <section className="page_404">
      <div className="text-center">
        <div className="four_zero_four_bg" />
        <div className="contant_box_404">
          <h3 className="h2 my-4">Look like you're lost</h3>
          <div className="d-flex justify-content-center">
            <NavButton title={`Go to Home`} route={"/"} icon />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound404;
