import { useEffect } from "react";
import {
  Career_Awards,
  Career_CoWorker,
  Career_Culture,
  Career_GameZone,
  Career_Picnic,
  Career_TeaCoffee,
  Career_Timing,
  Career_WorkHome,
} from "../config/constants";
import "../styles/Careers.css";
import AOS from "aos";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const Careers = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname, "", "Careers Page");
    AOS.init({ duration: 1000 });
  }, []);

  const AboutWorkItem = [
    {
      img: Career_Timing,
      title: "Flexible Timing",
    },
    {
      img: Career_Awards,
      title: "Awards & Recognization",
    },
    {
      img: Career_CoWorker,
      title: "Helpful Co-Workers",
    },
    {
      img: Career_WorkHome,
      title: "Work From Home",
    },
    {
      img: Career_GameZone,
      title: "Fully Equipped Game Zone",
    },
    {
      img: Career_TeaCoffee,
      title: "Unlimited Tea & Coffee",
    },
    {
      img: Career_Picnic,
      title: "2 Picnic a year",
    },
    {
      img: Career_Culture,
      title: "Awesome Work Culture",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Careers</title>
      </Helmet>
      <div className="careeePage-container">
        <div className="working-section" data-aos="fade-up">
          <h3> Why Join Us ? </h3>
          <div className="about-working-container">
            {AboutWorkItem.map(({ img, title }) => (
              <div className="about-working-cover">
                <div className="about-working-img">
                  <img src={img} alt={img} />
                </div>
                <div className="about-working-title">{title}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="opening-section" data-aos="fade-up">
          <h1>Current Opening</h1>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h3 className="accordion-header" id="headingOp1">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOp1"
                  aria-expanded="true"
                  aria-controls="collapseOp1"
                >
                  Senior Fullstack Developer
                </button>
              </h3>
              <div
                id="collapseOp1"
                className="accordion-collapse collapse"
                aria-labelledby="headingOp1"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <h3 className="small-text txt-gray">
                    <b>Job Summary</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <ul>
                      <li>
                        <b>Job Title :</b> Senior/Full Stack JavaScript
                        Developer{" "}
                      </li>
                      <li>
                        <b>Open Position :</b> 1{" "}
                      </li>
                      <li>
                        <b>Experience :</b> 2 to 5 Years{" "}
                      </li>
                      <li>
                        <b>Salary :</b> Based on experience and industry
                        standard{" "}
                      </li>
                      <li>
                        <b>Employment :</b> Full time{" "}
                      </li>
                    </ul>
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Required Skills & Qualifications : </b>
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <ul>
                      <li>
                        Strong knowledge of TypeScript and JavaScript, including
                        ES6/ES7 features
                      </li>
                      <li>
                        Proficient in ReactJS, including Redux and React Native
                      </li>
                      <li>Strong knowledge of NodeJS and ExpressJS</li>
                      <li>
                        Experience with RESTful API development and integration
                      </li>
                      <li>Knowledge of front-end optimization techniques</li>
                      <li>
                        Understanding of responsive design and cross-browser
                        compatibility
                      </li>
                      <li>
                        Knowledge of SQL and NoSQL databases, such as MongoDB
                        and MySQL
                      </li>
                      <li>Knowledge about GraphQL</li>
                      <li>
                        Experience with Git, code version control, and project
                        management tools
                      </li>
                      <li>
                        Ability to write clean, maintainable, and scalable code
                      </li>
                      <li>
                        Familiarity with unit testing, integration testing, and
                        end-to-end testing
                      </li>
                      <li>
                        Excellent problem-solving skills, ability to debug and
                        troubleshoot issues
                      </li>
                      <li>
                        Good communication skills and ability to work in a team
                        environment
                      </li>
                      <li>
                        Experience with TypeScript type definitions, interfaces,
                        and decorators.
                      </li>
                    </ul>
                  </h3>
                </div>
                <div className="text-apply-cover" data-aos="zoom-in">
                  To apply , email your resume at hr@knackroot.com
                </div>
              </div>
            </div>
            <div className="accordion-item mt-3">
              <h3 className="accordion-header" id="headingOp2">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOp2"
                  aria-expanded="true"
                  aria-controls="collapseOp2"
                >
                  Junior QA Engineer
                </button>
              </h3>
              <div
                id="collapseOp2"
                className="accordion-collapse collapse"
                aria-labelledby="headingOp2"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <h3 className="small-text txt-gray">
                    <b>Job Summary</b>
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <ul>
                      <li>
                        <b>Job Title :</b> Junior QA Engineer
                      </li>
                      <li>
                        <b>Open Position :</b> 1{" "}
                      </li>
                      <li>
                        <b>Experience :</b> 0 to 1 Years{" "}
                      </li>
                      <li>
                        <b>Salary :</b> 2 - 4 Lacs P.A.
                      </li>
                      <li>
                        <b>Employment :</b> Full time{" "}
                      </li>
                    </ul>
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <b>Required Skills & Qualifications : </b>
                  </h3>
                  <h3 className="small-text txt-gray mt-4">
                    <ul>
                      <li>Good knowledge of SDLC and STLC</li>
                      <li>Networking</li>
                      <li>Software Testing</li>
                      <li>Database Testing</li>
                      <li>Manual Testing</li>
                      <li>Performance Testing</li>
                      <li>Database Automation Testing</li>
                      <li>Quality Assurance Checking</li>
                      <li>Knowledge of Blockchain technology is a big plus.</li>
                    </ul>
                  </h3>
                </div>
                <div className="text-apply-cover" data-aos="zoom-in">
                  To apply , email your resume at hr@knackroot.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
