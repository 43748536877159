import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Image_Agriculture,
  Image_Areo,
  Image_Energy,
  Image_Finance,
  Image_Healthcare,
  Image_Insurance,
  Image_Manufacture,
  Image_Retail,
  INDUSTRY_ROUTE,
} from "../config/constants";
import AOS from "aos";
import "../styles/Industry.css";

export const AgriBusiness = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="industry-page-container">
      <div className="img-cover" data-aos="zoom-in">
        <img src={Image_Agriculture} alt="img" />
      </div>
      <div className="heading-cover my-4" data-aos="fade-up">
        <h4>Blockchain In Agriculture Business</h4>
      </div>
      <div className="content-cover my-4">
        <p className="mb-4">
          Blockchain technology can have several applications in agriculture,
          including improving supply chain transparency, traceability, and food
          safety.
        </p>
        <p className="mb-4">
          Here are some ways in which blockchain can work in agriculture :
        </p>
        <p className="mb-4 points">
          <b>1. Supply chain transparency : </b> Blockchain can provide
          end-to-end visibility into the supply chain, from the farmer to the
          consumer. This allows stakeholders to track products at every stage,
          ensuring transparency and accountability.
        </p>
        <p className="mb-4 points">
          <b>2. Traceability : </b> By using blockchain, stakeholders can easily
          trace the origin and journey of agricultural products, including their
          production, transportation, and storage history.
        </p>
        <p className="mb-4 points">
          <b>3. Food safety : </b> Blockchain can be used to monitor food safety
          by recording data about products' quality, authenticity, and safety
          throughout the supply chain. This information can be easily accessed
          by stakeholders, such as regulators, retailers, and consumers.
        </p>
        <p className="mb-4 points">
          <b>4. Smart contracts : </b> Smart contracts are self-executing
          agreements that can be programmed to trigger actions automatically
          based on pre-defined conditions. They can be used to streamline
          transactions and automate processes in the agriculture industry, such
          as the buying and selling of products.
        </p>
        <p className="mb-4 points">
          <b>5. Farm management : </b> Blockchain can help farmers manage their
          crops, livestock, and land more efficiently by providing real-time
          data on weather conditions, soil quality, and other factors that
          affect crop growth and yield.
        </p>
        <p className="text-center">
          Overall, blockchain technology has the potential to revolutionize the
          agriculture industry by increasing transparency, traceability, and
          efficiency in the supply chain, ultimately leading to safer,
          healthier, and more sustainable food systems.
        </p>
      </div>
    </div>
  );
};

export const HealthcareBusiness = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="industry-page-container">
      <div className="img-cover" data-aos="zoom-in">
        <img src={Image_Healthcare} alt="img" />
      </div>
      <div className="heading-cover my-4" data-aos="fade-up">
        <h4>Blockchain In Healthcare and Life Science</h4>
      </div>
      <div className="content-cover my-4">
        <p className="mb-4">
          Blockchain technology has the potential to revolutionize the
          healthcare and life sciences industry by providing a secure and
          transparent way to manage health data, track the supply chain of
          pharmaceuticals, and even facilitate medical research.
        </p>
        <p className="mb-4">
          Here are some ways in which blockchain can be used in healthcare and
          life sciences :
        </p>
        <p className="mb-4 points">
          <b>1. Medical record management : </b> Blockchain technology can be
          used to securely store and manage medical records. Patients can
          control who has access to their data, and healthcare providers can
          easily and securely share medical information with each other,
          improving the quality of care.
        </p>
        <p className="mb-4 points">
          <b>2. Clinical trial management : </b> Blockchain technology can
          improve the transparency and security of clinical trial data. It can
          ensure that trial data is tamper-proof, immutable, and accessible to
          authorized stakeholders. This can help prevent fraud and improve the
          accuracy and efficiency of clinical trials.
        </p>
        <p className="mb-4 points">
          <b>3. Supply chain management : </b> Blockchain technology can be used
          to track the supply chain of pharmaceuticals, ensuring that drugs are
          not counterfeit, expired, or diverted. This can improve patient safety
          and help prevent the spread of counterfeit drugs.
        </p>
        <p className="mb-4 points">
          <b>4. Medical research : </b> Blockchain technology can facilitate the
          sharing of medical research data between researchers and institutions,
          enabling collaboration and innovation. This can accelerate the
          discovery of new treatments and improve patient outcomes.
        </p>
        <p className="text-center">
          Overall, blockchain technology has the potential to improve the
          quality of care, increase patient safety, and accelerate medical
          innovation in the healthcare and life sciences industry.
        </p>
      </div>
    </div>
  );
};

export const InsuranceBusiness = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="industry-page-container">
      <div className="img-cover" data-aos="zoom-in">
        <img src={Image_Insurance} alt="img" />
      </div>
      <div className="heading-cover my-4" data-aos="fade-up">
        <h4>Blockchain In Insurance Business</h4>
      </div>
      <div className="content-cover my-4">
        <p className="mb-4">
          Blockchain technology has the potential to transform the insurance
          industry by enabling secure, transparent, and efficient transactions.
        </p>
        <p className="mb-4">
          Here are some ways in which blockchain can be used in insurance :
        </p>
        <p className="mb-4 points">
          <b>1. Claims processing : </b> With blockchain, insurance claims can
          be processed more efficiently and transparently. Claims information
          can be stored on the blockchain, which allows all parties to access
          and verify it, reducing the need for intermediaries and increasing
          trust.
        </p>
        <p className="mb-4 points">
          <b>2. Fraud detection : </b> Blockchain technology can help insurance
          companies to detect and prevent fraudulent activities. By using a
          distributed ledger, insurers can track transactions and verify the
          authenticity of information provided by customers.
        </p>
        <p className="mb-4 points">
          <b>3. Proof of insurance : </b> Blockchain can provide a secure and
          transparent way of proving insurance coverage. This could be
          particularly useful for industries such as healthcare, where there are
          often multiple parties involved in a transaction and a need for
          transparency.
        </p>
        <p className="mb-4 points">
          <b>4. Smart contracts : </b> Smart contracts are self-executing
          contracts with the terms of the agreement between buyer and seller
          being directly written into lines of code. Insurance policies can be
          written as smart contracts, which would automate the claims process
          and reduce costs.
        </p>
        <p className="mb-4 points">
          <b>5. Reinsurance : </b> Blockchain can be used for reinsurance, which
          is insurance for insurers. By using blockchain, reinsurers can reduce
          costs, increase transparency, and improve efficiency.
        </p>
        <p className="text-center">
          Overall, blockchain technology can help insurers to reduce costs,
          increase efficiency, and provide better services to customers.
          However, widespread adoption will depend on regulatory acceptance,
          industry collaboration, and technological infrastructure.
        </p>
      </div>
    </div>
  );
};

export const AeroBusiness = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="industry-page-container">
      <div className="img-cover" data-aos="zoom-in">
        <img src={Image_Areo} alt="img" />
      </div>
      <div className="heading-cover my-4" data-aos="fade-up">
        <h4>Blockchain In Aerospace Industry</h4>
      </div>
      <div className="content-cover my-4">
        <p className="mb-4">
          Blockchain technology can have several applications in the aerospace
          industry, particularly in areas such as supply chain management,
          aircraft maintenance, and aviation safety.
        </p>
        <p className="mb-4">
          Here are some examples of how blockchain can work in these areas :
        </p>
        <p className="mb-4 points">
          <b>1. Supply Chain Management : </b> Blockchain can be used to improve
          transparency and traceability in the supply chain of aerospace parts
          and components. By using a decentralized ledger, all parties involved
          in the supply chain can access and verify the authenticity of the data
          related to the parts, including their origin, ownership, and
          maintenance history.
        </p>
        <p className="mb-4 points">
          <b>2. Aircraft Maintenance : </b> Blockchain can be used to create a
          tamper-proof record of aircraft maintenance, repairs, and inspections.
          By recording all the maintenance activities on a blockchain ledger,
          airlines and regulatory bodies can access the information in
          real-time, which can help reduce the risk of errors and improve
          safety.
        </p>
        <p className="mb-4 points">
          <b>3. Aviation Safety : </b> Blockchain can be used to improve
          aviation safety by creating a secure and decentralized database of
          aviation-related information, such as weather data, flight plans, and
          pilot records. This information can be accessed by airlines,
          regulatory bodies, and other stakeholders to help them make more
          informed decisions and improve safety.
        </p>
        <p className="text-center">
          Overall, blockchain technology can help the aerospace industry improve
          efficiency, reduce costs, and enhance safety by providing a secure and
          transparent platform for sharing data and information.
        </p>
      </div>
    </div>
  );
};

export const RetailBusiness = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="industry-page-container">
      <div className="img-cover" data-aos="zoom-in">
        <img src={Image_Retail} alt="img" />
      </div>
      <div className="heading-cover my-4" data-aos="fade-up">
        <h4>Blockchain In Retail & CPG Industry</h4>
      </div>
      <div className="content-cover my-4">
        <p className="mb-4">
          Blockchain technology has the potential to transform various
          industries, including retail and Consumer Packaged Goods (CPG). In
          retail and CPG, blockchain can improve supply chain transparency,
          traceability, and efficiency.
        </p>
        <p className="mb-4">Here's how blockchain works in retail and CPG :</p>
        <p className="mb-4 points">
          <b>1. Supply chain transparency : </b> Blockchain provides a
          distributed ledger system that can track every product's movement from
          the manufacturer to the consumer. This ensures that every party
          involved in the supply chain has access to accurate and timely
          information, enabling them to make informed decisions.
        </p>
        <p className="mb-4 points">
          <b>2. Traceability : </b> Blockchain can help track the origin of
          products, including raw materials, manufacturing processes, and
          distribution channels. This ensures that retailers and consumers can
          verify that the products they are purchasing are authentic, safe, and
          ethically sourced.
        </p>
        <p className="mb-4 points">
          <b>3. Efficiency : </b> By using blockchain, retailers and CPG
          companies can automate several processes, such as payments, inventory
          management, and shipping. This reduces the cost and time required to
          manage the supply chain, enabling retailers to offer more competitive
          pricing and faster delivery times.
        </p>
        <p className="mb-4 points">
          <b>4. Loyalty programs : </b> Blockchain can be used to create loyalty
          programs that offer more personalized and secure rewards to customers.
          Blockchain-based loyalty programs can also ensure that rewards are
          distributed fairly and transparently.
        </p>
        <p className="mb-4 points">
          <b>5. Product provenance : </b> Blockchain can be used to track a
          product's history, including the manufacturing process, quality
          control checks, and the chain of custody. This information can be
          shared with customers to provide them with more information about the
          products they are purchasing.
        </p>
        <p className="text-center">
          Overall, blockchain technology has the potential to revolutionize the
          retail and CPG industries by improving supply chain transparency,
          traceability, and efficiency.
        </p>
      </div>
    </div>
  );
};

export const ManufacturingBusiness = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="industry-page-container">
      <div className="img-cover" data-aos="zoom-in">
        <img src={Image_Manufacture} alt="img" />
      </div>
      <div className="heading-cover my-4" data-aos="fade-up">
        <h4>Blockchain In Manufacturing & Hi-Tech Industry</h4>
      </div>
      <div className="content-cover my-4">
        <p className="mb-4">
          Blockchain technology can play a significant role in the manufacturing
          and Hi-Tech industries by providing a secure, transparent, and
          tamper-proof platform for tracking and sharing data across the supply
          chain.
        </p>
        <p className="mb-4 points">
          In manufacturing, blockchain can be used to record the entire
          production process, from the sourcing of raw materials to the final
          delivery of the finished product.
        </p>
        <p className="mb-4 points">
          By using a decentralized ledger, manufacturers can create a permanent
          and unalterable record of every transaction, ensuring transparency and
          traceability throughout the supply chain. This can help to reduce
          costs, increase efficiency, and improve product quality.
        </p>
        <p className="mb-4 points">
          In the Hi-Tech industry, blockchain can be used to secure intellectual
          property and confidential information, such as trade secrets, patents,
          and designs.
        </p>
        <p className="mb-4 points">
          By creating a decentralized system for sharing information,
          manufacturers can protect their intellectual property from theft or
          unauthorized use, while also streamlining collaboration and
          communication between different teams and partners.
        </p>
        <p className="text-center">
          Overall, blockchain technology has the potential to revolutionize the
          manufacturing and Hi-Tech industries by providing a secure,
          transparent, and efficient platform for tracking and sharing data
          across the supply chain.
        </p>
      </div>
    </div>
  );
};

export const EnergyCommBusiness = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="industry-page-container">
      <div className="img-cover" data-aos="zoom-in">
        <img src={Image_Energy} alt="img" />
      </div>
      <div className="heading-cover my-4" data-aos="fade-up">
        <h4>Blockchain In Energy , Communication & Services </h4>
      </div>
      <div className="content-cover my-4">
        <p className="mb-4">
          Blockchain technology has the potential to revolutionize the energy
          and communication sectors by enabling secure and decentralized
          transactions.
        </p>
        <p className="mb-4">
          Here are some examples of how blockchain can be applied in these
          industries :
        </p>
        <p className="mb-4 points">
          <b>1. Energy trading : </b> With blockchain, energy trading can be
          done in a decentralized and secure manner without the need for
          intermediaries. This can help to reduce transaction costs and increase
          transparency. For instance, peer-to-peer energy trading platforms
          using blockchain are being developed where individuals and companies
          can buy and sell excess energy generated from renewable sources.
        </p>
        <p className="mb-4 points">
          <b>2. Smart grids : </b> Blockchain can help to create a more
          efficient and reliable energy grid by enabling real-time monitoring
          and control of energy production, distribution, and consumption. This
          can help to reduce waste and ensure a stable supply of energy.
        </p>
        <p className="mb-4 points">
          <b>3. Energy storage : </b> Blockchain can be used to create
          decentralized energy storage systems that can be managed by
          individuals and communities. This can help to increase energy security
          and resilience, particularly in areas with unreliable or insufficient
          grid infrastructure.
        </p>
        <p className="mb-4 points">
          <b>4. Telecommunications : </b> Blockchain can be used to create
          decentralized communication networks that are more secure and private
          than traditional centralized networks. This can be particularly useful
          in regions with poor network infrastructure or where there are
          concerns about government surveillance.
        </p>
        <p className="mb-4 points">
          <b>5. Identity and authentication : </b> Blockchain can help to create
          secure and decentralized identity and authentication systems for
          energy and communication services. This can help to reduce fraud and
          ensure that only authorized individuals have access to these services.
        </p>
        <p className="text-center">
          Overall, blockchain has the potential to transform the energy and
          communication sectors by enabling secure and decentralized
          transactions, increasing efficiency and reliability, and enhancing
          privacy and security.
        </p>
      </div>
    </div>
  );
};

export const FinanceService = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="industry-page-container">
      <div className="img-cover" data-aos="zoom-in">
        <img src={Image_Finance} alt="img" />
      </div>
      <div className="heading-cover my-4" data-aos="fade-up">
        <h4>Blockchain In Finance Services </h4>
      </div>
      <div className="content-cover my-4">
        <p className="mb-4">
          Blockchain technology has the potential to revolutionize the financial
          services industry in several ways.
        </p>
        <p className="mb-4">
          Here are some of the ways in which blockchain is being used in
          financial services :
        </p>
        <p className="mb-4 points">
          <b>1. Payments and Money Transfers : </b> Blockchain technology can be
          used to facilitate fast and secure cross-border payments and money
          transfers. Blockchain-based payment systems can eliminate
          intermediaries and reduce transaction fees, making it cheaper and
          faster to send and receive money.
        </p>
        <p className="mb-4 points">
          <b>2. Digital Identity Management : </b> Blockchain technology can be
          used to create digital identities that are secure and tamper-proof.
          This can help prevent identity theft and fraud in financial
          transactions.
        </p>
        <p className="mb-4 points">
          <b>3. Trade Finance : </b> Blockchain technology can be used to create
          more efficient and secure trade finance systems. By using smart
          contracts, blockchain-based trade finance systems can automate many of
          the manual processes involved in trade finance, reducing costs and
          speeding up transactions.
        </p>
        <p className="mb-4 points">
          <b>4. Securities Trading : </b> Blockchain technology can be used to
          create more efficient and secure securities trading systems. By using
          blockchain-based ledgers, securities trades can be settled faster and
          with greater transparency, reducing the risk of errors and fraud.
        </p>
        <p className="mb-4 points">
          <b>5. Insurance : </b> Blockchain technology can be used to create
          more efficient and transparent insurance systems. By using smart
          contracts, insurance claims can be automatically verified and settled,
          reducing the time and cost involved in claims processing.
        </p>
        <p className="text-center">
          Overall, the use of blockchain technology in financial services has
          the potential to increase efficiency, reduce costs, and improve
          security and transparency in financial transactions.
        </p>
      </div>
    </div>
  );
};

const Industry = () => {
  const {
    AGRICULTURE,
    AEROSPACE,
    ENERGY,
    FINANCIAL,
    HEALTHCARE,
    INSURANCE,
    MANUFACTURING,
    RETAIL,
  } = INDUSTRY_ROUTE;

  const { industry } = useParams();

  switch (industry) {
    case AGRICULTURE:
      return <AgriBusiness />;
    case AEROSPACE:
      return <AeroBusiness />;
    case ENERGY:
      return <EnergyCommBusiness />;
    case FINANCIAL:
      return <FinanceService />;
    case HEALTHCARE:
      return <HealthcareBusiness />;
    case INSURANCE:
      return <InsuranceBusiness />;
    case MANUFACTURING:
      return <ManufacturingBusiness />;
    case RETAIL:
      return <RetailBusiness />;
    default:
      return <></>;
  }
};

export default Industry;
