import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  POST_ROUTE,
  Img_GoodFirmBlog,
  Img_PublicPrivateBlog,
  Img_LibraBlog,
  Img_UsecaseBlog,
  Img_NFTBlog,
  Img_TokenStdBlog,
  Img_ZkRollupBlog,
  Img_SmartContractBlog,
  Img_RealEstateBlog,
  Img_DeFiBlog,
  Img_BlcEcomBlog,
  Img_BlcGovernBlog,
  Img_BlcDexBlog,
  Img_BlcLayer2Blog,
  Img_BlcIoTBlog,
  Img_ConsensusAlgoBlog,
  Img_DaoBlog,
  Img_HyperledgerBlog,
  Img_WorldCoinBlog,
  Img_CrossChainBlog,
  Img_PolygonPoSBlog,
  Img_DFSBlog,
} from "../../config/constants";
import "../../styles/Blog.css";
import AOS from "aos";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const BlogCard = (props) => {
  const { img, date, title, description, route } = props;
  return (
    <Link to={`/blog/${route}`} className="blog-card-cover">
      <div className="blog-card-img">
        <img src={img} alt={img} />
      </div>
      <div className="blog-card-content">
        <p className="blog-card-date">{date}</p>
        <p className="blog-card-title">{title}</p>
        <p className="blog-card-description">{description}</p>
      </div>
    </Link>
  );
};

const Blog = (props) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname, "", "Blog Page");
    AOS.init({ duration: 1000 });
  }, []);

  const { isLatestBlog } = props;
  const {
    POST_1,
    POST_2,
    POST_3,
    POST_4,
    POST_5,
    POST_6,
    POST_7,
    POST_8,
    POST_9,
    POST_10,
    POST_11,
    POST_12,
    POST_13,
    POST_14,
    POST_15,
    POST_16,
    POST_17,
    POST_18,
    POST_19,
    POST_20,
    POST_21,
    POST_22,
  } = POST_ROUTE;

  const BlogCardsData = [
    {
      img: Img_GoodFirmBlog,
      date: "January 30, 2023",
      title:
        "Knackroot Technolabs Recognized by GoodFirms as the Best Company to Work With",
      description:
        "GoodFirms recently recognized Knackroot Technolabs for its vast experience and versatile skills that put the Company . . .",
      route: POST_1,
    },
    {
      img: Img_PolygonPoSBlog,
      date: "Aug 18, 2023",
      title: "Exploring Polygon's Proof of Stake Architecture",
      description:
        "Blockchain technology has transformed various industries by providing a decentralized and transparent platform for secure transactions . . .",
      route: POST_22,
    },
    {
      img: Img_DFSBlog,
      date: "Aug 17, 2023",
      title:
        "Empowering Data Ownership: The Rise of Decentralized File Storage",
      description:
        "Decentralized file storage involves distributing data across a network of nodes rather than relying on a central server. This network . . .",
      route: POST_21,
    },
    {
      img: Img_CrossChainBlog,
      date: "Aug 16, 2023",
      title: "Unveiling Cross-Chain Bridges in Blockchain",
      description:
        "The rapid evolution of blockchain technology has not only revolutionized the world of finance but also laid the foundation for an entirely . . .",
      route: POST_20,
    },
    {
      img: Img_WorldCoinBlog,
      date: "Aug 14, 2023",
      title: "World Coin : A Glimpse into the Future of Global Currency",
      description:
        "The world of finance and economics is on the brink of a major transformation with the emergence of digital currencies . . .",
      route: POST_19,
    },
    {
      img: Img_HyperledgerBlog,
      date: "Aug 09, 2023",
      title:
        "Exploring Hyperledger Fabric : A Deep Dive into Enterprise Blockchain",
      description:
        "Blockchain technology has revolutionized the way we perceive and manage data across industries. Its distributed and immutable . . .",
      route: POST_18,
    },
    {
      img: Img_DaoBlog,
      date: "July 28, 2023",
      title: "The Rise of DAOs: A Revolutionary Path to Transparent Governance",
      description:
        "A Decentralized Autonomous Organization, or DAO, is a self-governing entity that operates based on pre-defined rules and smart contracts . . .",
      route: POST_17,
    },
    {
      img: Img_ConsensusAlgoBlog,
      date: "July 18, 2023",
      title:
        "Consensus Algorithms: The Backbone of Blockchain and Distributed Ledger Technology",
      description:
        "Blockchain is a robust, distributed, and decentralized network that offers attributes such as immutability, privacy, security . . .",
      route: POST_16,
    },
    {
      img: Img_BlcIoTBlog,
      date: "June 07, 2023",
      title:
        "Blockchain and Internet of Things : Explore the intersection of blockchain and IoT",
      description:
        "The Internet of Things ( IoT ) refers to a collection of interconnected networks and diverse devices . . .",
      route: POST_15,
    },
    {
      img: Img_BlcLayer2Blog,
      date: "May 18, 2023",
      title:
        "Exploring Layer 2 Solutions: Enhancing Blockchain Performance and Scalability",
      description:
        "Layer 2 solutions in blockchain refer to protocols designed to enhance scalability, privacy, and other essential . . .",
      route: POST_14,
    },
    {
      img: Img_BlcDexBlog,
      date: "May 16, 2023",
      title: "Decentralized Exchanges (DEXs) and the Future of Trading",
      description:
        "Decentralized exchanges, often referred to as DEXs, are crypto trading platforms where users can trade directly with each other without the involvement . . .",
      route: POST_13,
    },
    {
      img: Img_BlcGovernBlog,
      date: "May 09, 2023",
      title:
        "The Adoption of Blockchain Technology in Government and Public Sector",
      description:
        "The utilization of blockchain technology can bring about a transformative impact on the delivery of government services to citizens . . .",
      route: POST_12,
    },
    {
      img: Img_BlcEcomBlog,
      date: "May 08, 2023",
      title: "The Role of Blockchain in E-commerce and Online Payments",
      description:
        "Ethereum and Bitcoin are the two blockchain technologies most often employed in the eCommerce industry when it comes to ways . . .",
      route: POST_11,
    },
    {
      img: Img_DeFiBlog,
      date: "May 04, 2023",
      title: "The Future of DeFi and Its Impact on Traditional Finance",
      description:
        "Decentralized Finance, or DeFi, is a rapidly growing sector in the world of finance. It is based on blockchain technology and . . .",
      route: POST_10,
    },
    {
      img: Img_RealEstateBlog,
      date: "May 03, 2023",
      title: "How can blockchain change the real estate business",
      description:
        "Blockchain technology has been making waves in various industries, and real estate is no exception. The potential of blockchain . . .",
      route: POST_9,
    },
    {
      img: Img_SmartContractBlog,
      date: "May 02, 2023",
      title: "Understand the role of smart contracts in blockchain technology",
      description:
        "Smart contracts are simply programs stored on a blockchain that run when predetermined conditions are met. They typically are . . .",
      route: POST_8,
    },
    {
      img: Img_ZkRollupBlog,
      date: "May 01, 2023",
      title:
        "ZK Rollups : The Future of Scalable and Secure Blockchain Transactions",
      description:
        "Zero-knowledge rollups (ZK-rollups) are layer 2 scaling solutions that increase throughput on Ethereum Mainnet by moving . . .",
      route: POST_7,
    },
    {
      img: Img_TokenStdBlog,
      date: "April 28, 2023",
      title:
        "Understanding Token Standards - A Deep Dive into ERC20, ERC721 & ERC1155",
      description:
        "ERC token standards explain certain rules for all the ERC tokens built on the Ethereum blockchain. Ethereum’s community . . .",
      route: POST_6,
    },
    {
      img: Img_NFTBlog,
      date: "April 27, 2023",
      title: "The Rise of NFTs : Exploring the Latest Trends and Use Cases",
      description:
        "The technical features of blockchains such as i) the use of distributed ledger technology ii) cryptographically . . .",
      route: POST_5,
    },
    {
      img: Img_UsecaseBlog,
      date: "July 29, 2018",
      title: "Top 5 most compelling use cases for blockchain technology",
      description:
        "The technical features of blockchains such as i) the use of distributed ledger technology ii) cryptographically . . .",
      route: POST_2,
    },
    {
      img: Img_LibraBlog,
      date: "July 29, 2018",
      title: "How Will Facebook’s Libra “Blockchain” Really Work ?",
      description:
        "The Libra protocol allows a set of replicas referred to as validators from different authorities to jointly maintain . . .",
      route: POST_3,
    },
    {
      img: Img_PublicPrivateBlog,
      date: "July 29, 2018",
      title: "Public Vs Private Blockchain In A Nutshell",
      description:
        "When we try to understand the main difference between a public and private blockchain, it is important to . . .",
      route: POST_4,
    },
  ];

  return (
    <>
      {!isLatestBlog && (
        <Helmet>
          <title>Blogs</title>
          <meta
            name="description"
            content="Learn Blockchain trends from our latest news and blogs"
          />
        </Helmet>
      )}
      <div className="blog-container">
        {isLatestBlog ? (
          <div className="title-container" data-aos="fade">
            <h3>Latest Blog 📚 </h3>
            <h4 className="subtitle text-center">
              Learn Blockchain trends from our latest news
            </h4>
          </div>
        ) : (
          <div className="title-container" data-aos="fade">
            <h3>
              Learn Blockchain trends from
              <br />
              our latest news 📚
            </h3>
          </div>
        )}
        <div className="blog-cards-container" data-aos="fade">
          {(isLatestBlog ? BlogCardsData.slice(0, 3) : BlogCardsData).map(
            ({ img, title, description, date, route }, i) => (
              <BlogCard
                key={i}
                img={img}
                date={date}
                title={title}
                description={description}
                route={route}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Blog;
