import { useEffect } from "react";
import {
  Group,
  Rectangle12,
  Rectangle16,
  Rectangle17,
  Rectangle19,
} from "../config/constants";
import "../styles/styles.css";
import "../styles/media.css";
import AOS from "aos";
import NavButton from "./NavButton";

const HeroSection = () => {
  
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="section-main">
      <img src={Rectangle12} alt="Rectangle12" className="rectangle-12" />
      <img src={Rectangle16} alt="Rectangle16" className="rectangle-16" />
      <img src={Rectangle17} alt="Rectangle17" className="rectangle-17" />
      <img src={Rectangle19} alt="Rectangle19" className="rectangle-19" />
      <div className="main-content">
        <div className="left-side">
          <div className="cover">
            <h3 className="title main-text-1">
              Transform your <br />
              Blockchain Ideas to <br />
              <span className="txt-cyan"> Business </span>{" "}
              <span> &#9757; </span>
            </h3>
            <h4 className="main-text-2">
              Looking for experts who can help you construct your business
              <br />
              idea into a real blockchain solution? We can help you !
            </h4>
            <NavButton route={"/services/smart-contract-development"} title={`Let's Start`} icon />
          </div>
        </div>
        <div className="right-side" data-aos="zoom-in">
          <img src={Group} alt="GroupImg" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
