import { useEffect } from "react";
import NavButton from "./NavButton";
import AOS from "aos";
import "../styles/CardItem.css";

const CardItem = (props) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const { img, heading, description, route, animate, isMain } = props;

  return (
    <div className="card-container" data-aos={animate}>
      <div className="icon">
        <img src={img} alt={heading} />
      </div>
      {isMain ? (
        <h1 className="heading-text">{heading}</h1>
      ) : (
        <div className="heading-text">{heading}</div>
      )}
      <div className="description-text">{description}</div>
      <div className="btnLink-cover">
        <NavButton title={`View`} route={route} icon />
      </div>
    </div>
  );
};

export default CardItem;
