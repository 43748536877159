import { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  Img_Insurance,
  Img_Healthcare,
  Img_Agri,
  Img_Aero,
  Img_Retail,
  Img_Hitech,
  Img_EnergyCom,
  Img_Finance,
  Icon_Underline,
  INDUSTRY_ROUTE,
} from "../../config/constants";
import NavButton from "../NavButton";
import AOS from "aos";

const IndustryCarousel = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const {
    AGRICULTURE,
    AEROSPACE,
    ENERGY,
    FINANCIAL,
    HEALTHCARE,
    INSURANCE,
    MANUFACTURING,
    RETAIL,
  } = INDUSTRY_ROUTE;

  const CardData = [
    {
      img: Img_Insurance,
      title: "Insurance",
      description:
        "Commercial Insurance & Reinsurance, P2P Insurance, Subrogation",
      route: `/usecases/${INSURANCE}`,
    },
    {
      img: Img_Healthcare,
      title: "Healthcare and Life Sciences",
      description:
        "Health Records, Pharma Supply Chain Transparency, Provider Data Management",
      route: `/usecases/${HEALTHCARE}`,
    },
    {
      img: Img_Finance,
      title: "Financial Services",
      description:
        "Corporate Bonds (Trade & Settlement), Cross Border Payments, Global Remittance, P2P Payments, Trade Finance",
      route: `/usecases/${FINANCIAL}`,
    },
    {
      img: Img_Agri,
      title: "Agri-Business",
      description:
        "Farm Equipment Sharing, Post Harvest Produce Logistics, Verifying Product Integrity and Authenticity",
      route: `/usecases/${AGRICULTURE}`,
    },
    {
      img: Img_Aero,
      title: "Aero",
      description:
        "Air plane damage tracking, IP Management for component design",
      route: `/usecases/${AEROSPACE}`,
    },
    {
      img: Img_Retail,
      title: "Retail and CPG",
      description:
        "Distributed Marketplace, Food Safety in Supply Chain, Inventory Tracking, Loyalty Reward Points",
      route: `/usecases/${RETAIL}`,
    },
    {
      img: Img_Hitech,
      title: "Manufacturing & Hi Tech",
      description:
        "Inventory Visibility and, Parts Provenance, Service Order Shipment",
      route: `/usecases/${MANUFACTURING}`,
    },
    {
      img: Img_EnergyCom,
      title: "Energy, Communication and Services",
      description:
        "Cargo Assurance , Demurrage and Claims, Wholesale Energy Supply",
      route: `/usecases/${ENERGY}`,
    },
  ];

  const options = {
    items: 3,
    loop: true,
    margin: 15,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      660: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };

  return (
    <div className="pc-main">
      <div className="custom-container">
        <h3 className="title txt-blue text-center" data-aos="fade">
          Blockchain Proof of Concepts ☝🏻
        </h3>
        <span className="text-center" data-aos="fade">
          <img className="mb-5 mt-2" src={Icon_Underline} alt="UnderlineIcon" />
        </span>

        <div data-aos="fade">
          <OwlCarousel
            className="owl-carousel owl-theme"
            id="pcSlider"
            {...options}
          >
            {CardData.map(({ title, description, img, route }, i) => (
              <div className="pc-card" key={i}>
                <div className="pc-img">
                  <img src={img} alt="Img_Insurance" />
                </div>
                <div className="pc-data mt-3 txt-blue">
                  <h3 className="subtitle text-center">{title}</h3>
                  <h4 className="small-text text-center mt-3">{description}</h4>
                </div>
                <div className="sol-btn">
                  <NavButton route={route} title={"View"} icon />
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default IndustryCarousel;
