import { useEffect } from "react";
import {
  IconCryptoExc,
  IconCryptoWal,
  IconMiningSoft,
  IconPrivateBloc,
  IconPublicBloc,
  IconSTO,
  IconSmartCon,
  IconTradeBot,
  SERVICE_ROUTE,
} from "../config/constants";
import "../styles/Services.css";
import AOS from "aos";
import CardItem from "./CardItem";

const ServiceList = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const {
    SMART_CONTRACT,
    PRIVATE_BLOCKCHAIN,
    PUBLIC_BLOCKCHAIN,
    DEFI,
    CRYPTO_EXCHANGE,
    CRYPTO_WALLET,
    MINING_SOFTWARE,
    NFT_MARKETPLACE,
    STO,
    TRADING_BOTS,
    WEB3_DEVELOPMENT,
    WEB3_MIGRATION,
  } = SERVICE_ROUTE;

  const ServiceItemData = [
    {
      img: IconSmartCon,
      heading: "Smart Contract Development",
      description:
        "Smart contracts are self-executing contracts that are programmed to automatically execute . . .",
      route: `/services/${SMART_CONTRACT}`,
      animate: "flip-right",
      isMain: true,
    },
    {
      img: IconPrivateBloc,
      heading: "Private Blockchain Development",
      description:
        "Private blockchain development is the process of creating a secure and decentralized ledger . . .",
      route: `/services/${PRIVATE_BLOCKCHAIN}`,
      animate: "zoom-in",
      isMain: false,
    },
    {
      img: IconPublicBloc,
      heading: "Public Blockchain Development",
      description:
        "Public blockchains are open networks that allow anyone to participate in the network . . .",
      route: `/services/${PUBLIC_BLOCKCHAIN}`,
      animate: "flip-left",
      isMain: false,
    },
    {
      img: IconSmartCon,
      heading: "DeFi Development",
      description:
        "Decentralized Finance (DeFi) has emerged as a popular and revolutionary approach . . .",
      route: `/services/${DEFI}`,
      animate: "flip-right",
      isMain: true,
    },
    {
      img: IconPrivateBloc,
      heading: "NFT Marketplace Development",
      description:
        "Non-Fungible Tokens (NFTs) have become a popular and revolutionary approach . . .",
      route: `/services/${NFT_MARKETPLACE}`,
      animate: "zoom-in",
      isMain: true,
    },
    {
      img: IconPublicBloc,
      heading: "Web3 FullStack Development",
      description:
        "Web3 is the next generation of the internet, which promises to revolutionize the way . . .",
      route: `/services/${WEB3_DEVELOPMENT}`,
      animate: "flip-left",
      isMain: true,
    },
    {
      img: IconMiningSoft,
      heading: "Mining Software",
      description:
        "Cryptocurrency mining, or cryptomining, is a process in which transactions for various . . .",
      route: `/services/${MINING_SOFTWARE}`,
      animate: "flip-right",
      isMain: false,
    },
    {
      img: IconCryptoExc,
      heading: "Crypto Exchange Development",
      description:
        "Cryptocurrency trading has become a popular investment option for individuals . . .",
      route: `/services/${CRYPTO_EXCHANGE}`,
      animate: "zoom-in",
      isMain: false,
    },
    {
      img: IconSmartCon,
      heading: "Web2 to Web3 Migration",
      description:
        "Web2 to web3 migration refers to the process of transitioning a website or application . . .",
      route: `/services/${WEB3_MIGRATION}`,
      animate: "flip-left",
      isMain: false,
    },
    {
      img: IconTradeBot,
      heading: "Trading Bots Development",
      description:
        "Trading bots have become an essential tool for traders who want to automate their strategies . . .",
      route: `/services/${TRADING_BOTS}`,
      animate: "flip-right",
      isMain: false,
    },
    {
      img: IconSTO,
      heading: "STO",
      description:
        "Security Token Offering (STO) is a type of fundraising that is performed with a company . . .",
      route: `/services/${STO}`,
      animate: "zoom-in",
      isMain: false,
    },
    {
      img: IconCryptoWal,
      heading: "Crypto Wallets Development",
      description:
        "Cryptocurrency wallets or crypto wallets are digitally secured systems that allow receiving . . .",
      route: `/services/${CRYPTO_WALLET}`,
      animate: "flip-left",
      isMain: false,
    },
  ];

  return (
    <div className="service-container">
      <div className="service-title-cover">
        <span>&#11088;</span> <span>Services</span> <span>&#11088;</span>
      </div>
      <div className="service-description">
        Have an idea for a blockchain product? Let’s transform it into reality.
        Leverage our paramount blockchain development services and unlock your
        business potential.
      </div>
      <div className="service-item-cover">
        {ServiceItemData.map(
          ({ img, heading, description, route, animate, isMain }, i) => (
            <CardItem
              key={i}
              img={img}
              heading={heading}
              description={description}
              route={route}
              animate={animate}
              isMain={isMain}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ServiceList;
