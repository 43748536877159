import { useEffect } from "react";
import NavButton from "../components/NavButton";
import AOS from "aos";
import { Icon_Location, Icon_Phone } from "../config/constants";
import "../styles/media.css";
import "../styles/styles.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const Contact = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname, "", "Contact Page")
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Please contact us for blockchain development services."
        />
      </Helmet>
      <div className="contact-form">
        <div className="contact-title bg-blue" data-aos="fade">
          <h1 className="title txt-white">Contact Us</h1>
        </div>
        <div className="contact-form-sub">
          <div className="contact-left" data-aos="fade-right">
            <div className="left-top">
              <p className="subtitle">
                <span className="txt-blue">
                  Contact our support or make appointment with
                </span>
                <span className="txt-cyan"> our consultant </span>
              </p>
              <p className="small-text txt-gray">
                Please contact us using the information below. For additional
                information on our blockchain consulting services, please visit
                the appropriate page on our site.
              </p>
            </div>
            <div className="left-bottom">
              <p className="small-text txt-gray pointer">
                +91-9898777397
              </p>
              <p className="small-text txt-gray pointer">
                info@knackroot.com
              </p>
              <p className="small-text txt-gray pointer">
                Connekt, 13th Floor, <br />
                Gala Empire, Opp. T.V. Tower, <br />
                Drive In Rd, Ahmedabad, <br />
                Gujarat 380052
              </p>
              <div className="sol-btn">
                <NavButton
                  icon
                  title={"View On Map"}
                  route={"https://g.page/knackroot?share"}
                />
              </div>
            </div>
          </div>
          <div className="contact-right" data-aos="fade-left">
            <form
              action="https://formsubmit.co/07acfa819b3a0c0273dd38672d8f3b2a"
              method="POST"
            >
              <input
                type="hidden"
                name="_subject"
                value="New Enquiry On Website !"
              />
              <input
                type="hidden"
                name="_next"
                value="https://knackroot.com/contact.html"
              />
              <input type="hidden" name="_captcha" value="false" />
              <input type="hidden" name="_template" value="table" />
              <input type="text" name="_honey" style={{ display: "none" }} />
              <p>
                <span className="form-data-title txt-blue"> Full Name </span>
                <input
                  className="contact-input form-placeholder"
                  id="name"
                  placeholder="Type Here . . ."
                  type="text"
                  name="Name"
                  required
                />
              </p>
              <p>
                <span className="form-data-title txt-blue"> Email </span>
                <input
                  className="contact-input form-placeholder"
                  id="email"
                  placeholder="Type Here . . ."
                  type="email"
                  name="Email"
                  required
                />
              </p>
              <p>
                <span className="form-data-title txt-blue"> Subject </span>
                <input
                  className="contact-input form-placeholder"
                  id="subject"
                  placeholder="Type Here . . ."
                  type="text"
                  name="Subject"
                  required
                />
              </p>
              <p>
                <span className="form-data-title txt-blue"> Message </span>
                <textarea
                  className="contact-input message-input form-placeholder"
                  id="message"
                  placeholder="Type Here . . ."
                  type="text"
                  name="Message"
                  required
                ></textarea>
              </p>
              <p className="mt-4 align-items-center">
                <button
                  onclick="showLable()"
                  className="button-icon"
                  type="submit"
                >
                  Submit
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
      <div
        className="branch-cover bg-blue txt-white d-flex pt-5"
        data-aos="fade"
      >
        <div className="usa">
          <div className="usa-cover">
            <h3 className="subtitle">USA</h3>
            <div className="contact-cover">
              <div className="icons pe-3">
                <img src={Icon_Location} alt="Icon_Location" />
              </div>
              <div className="small-text pointer">
                3424 harvest bounty DR ,
                <br />
                Apartment #11101 , <br />
                Richmond , TX , 77406
              </div>
            </div>
            <div className="contact-cover">
              <div className="icons pe-3">
                <img src={Icon_Phone} alt="Icon_Phone" />
              </div>
              <div className="small-text pointer">
                +1 732 277 7359
              </div>
            </div>
          </div>
        </div>
        <div className="aus">
          <div className="aus-cover">
            <h3 className="subtitle">Australia</h3>
            <div className="contact-cover">
              <div className="icons pe-3">
                <img src={Icon_Location} alt="Icon_Location" />
              </div>
              <div className="small-text pointer">
                1/109 Ridge street <br />
                greenslopes , QLD 4120 <br />
                Australia
              </div>
            </div>
            <div className="contact-cover">
              <div className="icons pe-3">
                <img src={Icon_Phone} alt="Icon_Phone" />
              </div>
              <div className="small-text pointer">
                +61 470 365 107
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
