import { useEffect } from "react";
import { Img_SmartContractBlog } from "../../config/constants";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const Post8 = () => {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname, "", "Smart Contracts Blog");
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Knackroot - Smart Contracts in blockchain technology Transactions
        </title>
        <meta
          name="keywords"
          content="smart contract, smart contract development, blockchain smart contract, ethereum smart contract"
        />
      </Helmet>
      <div className="blogs-container">
        <div className="blog-img-container mt-4">
          <img
            className="blg-img"
            src={Img_SmartContractBlog}
            alt="Img_SmartContractBlog"
          />
        </div>
        <div className="blog-contents txt-blue mb-5">
          <h1 className="title my-4">
            Understanding the role of smart contracts in blockchain technology
          </h1>
          <h4 className="small-text">
            Smart contracts are simply programs stored on a blockchain that run
            when predetermined conditions are met. They typically are used to
            automate the execution of an agreement so that all participants can
            be immediately certain of the outcome, without any intermediary’s
            involvement or time loss. They can also automate a workflow,
            triggering the next action when conditions are met.
          </h4>
          <h4 className="small-text mt-3">
            A Smart Contract ( or cryptocontract ) is a computer program that
            directly and automatically controls the transfer of digital assets
            between the parties under certain conditions. A smart contract works
            in the same way as a traditional contract while also automatically
            enforcing the contract.
          </h4>
          <h4 className="small-text mt-3">
            Smart contracts are programs that execute exactly as they are set
            up( coded, programmed ) by their creators. Just like a traditional
            contract is enforceable by law, smart contracts are enforceable by
            code.
          </h4>
          <h3 className="subtitle my-3">How does smart contract work ? </h3>
          <h4 className="small-text mt-4">
            A smart contract is just a digital contract with the security coding
            of the blockchain.
            <br /> <br />
            <ul>
              <li>
                It has details and permissions written in code that require an
                exact sequence of events to take place to trigger the agreement
                of the terms mentioned in the smart contract.
              </li>
              <li className="mt-3">
                It can also include the time constraints that can introduce
                deadlines in the contract.
              </li>
              <li className="mt-3">
                Every smart contract has its address in the blockchain. The
                contract can be interacted with by using its address presuming
                the contract has been broadcasted on the network.
              </li>
            </ul>
            The idea behind smart contracts is pretty simple. They are executed
            on a basis of simple logic, IF-THEN for example :
            <ul className="mt-3">
              <li>
                IF you send object A, THEN the sum ( of money, in cryptocurrency
                ) will be transferred to you.
              </li>
              <li className="mt-3">
                IF you transfer a certain amount of digital assets
                (cryptocurrency, for example, ether, bitcoin), THEN the A object
                will be transferred to you.
              </li>
              <li className="mt-3">
                IF I finish the work, THEN the digital assets mentioned in the
                contract will be transferred to me.
              </li>
            </ul>
          </h4>

          <h3 className="subtitle my-4">Features of Smart Contracts :</h3>

          <h4 className="small-text">
            <b>1. Immutable :</b> <br /> <br />
            Once deployed smart contract cannot be changed, it can only be
            removed as long as the functionality is implemented previously.
          </h4>

          <h4 className="small-text mt-4">
            <b>2. Distributed :</b> <br /> <br />
            Everyone on the network is guaranteed to have a copy of all the
            conditions of the smart contract and they cannot be changed by one
            of the parties. A smart contract is replicated and distributed by
            all the nodes connected to the network.
          </h4>

          <h4 className="small-text mt-4">
            <b>3. Autonomy :</b> <br /> <br />
            There is no third party involved. The contract is made by you and
            shared between the parties. No intermediaries are involved which
            minimizes bullying and grants full authority to the dealing parties.
            Also, the smart contract is maintained and executed by all the nodes
            on the network, thus removing all the controlling power from any one
            party’s hand.
          </h4>

          <h4 className="small-text mt-4">
            <b>4. Deterministic :</b> <br /> <br />
            Smart contracts can only perform functions for which they are
            designed only when the required conditions are met. The final
            outcome will not vary, no matter who executes the smart contract.
          </h4>

          <h4 className="small-text mt-4">
            <b>5. Customizable :</b> <br /> <br />
            Smart contracts have the ability for modification or we can say
            customization before being launched to do what the user wants it to
            do.
          </h4>

          <h4 className="small-text mt-4">
            <b>6. Transparent :</b> <br /> <br />
            Smart contracts are always stored on a public distributed ledger
            called blockchain due to which the code is visible to everyone,
            whether or not they are participants in the smart contract.
          </h4>

          <h4 className="small-text mt-4">
            <b>7. Trustless :</b> <br /> <br />
            These are not required by third parties to verify the integrity of
            the process or to check whether the required conditions are met.
          </h4>

          <h3 className="subtitle my-4">Advantages of Smart Contracts :</h3>

          <h4 className="small-text mt-4">
            <b>1. Reduce fraud :</b> Fraudulent activity detection and
            reduction. Smart contracts are stored in the blockchain. Forcefully
            modifying the blockchain is very difficult as it’s
            computation-intensive. Also, a violation of the smart contract can
            be detected by the nodes in the network and such a violation attempt
            is marked invalid and not stored in the blockchain.
          </h4>
          <h4 className="small-text mt-4">
            <b>2. Cost-efficiency :</b> The application of smart contracts
            eliminates the need for intermediaries(brokers, lawyers, notaries,
            witnesses, etc.) leading to reduced costs. Also eliminates paperwork
            leading to paper saving and money-saving.
          </h4>
          <h4 className="small-text mt-4">
            <b>3. Enhanced trust :</b> Business agreements are automatically
            executed and enforced. Plus, these agreements are immutable and
            therefore unbreakable and undeniable.
          </h4>
          <h4 className="small-text mt-4">
            <b>4. Autonomy :</b> There are direct dealings between parties.
            Smart contracts remove the need for intermediaries and allow for
            transparent, direct relationships with customers.
          </h4>
          <h4 className="small-text mt-4">
            <b>5. Recordkeeping :</b> All contract transactions are stored in
            chronological order in the blockchain and can be accessed along with
            the complete audit trail. However, the parties involved can be
            secured cryptographically for full privacy.
          </h4>

          <h3 className="subtitle my-4">Disadvantages of Smart Contracts :</h3>

          <h4 className="small-text mt-4">
            <b>1. Immutable :</b> They are practically immutable. Whenever there
            is a change that has to be incorporated into the contract, a new
            contract has to be made and implemented in the blockchain.
          </h4>
          <h4 className="small-text mt-4">
            <b>2. No regulations :</b> A lack of international regulations
            focusing on blockchain technology(and related technology like smart
            contracts, mining, and use cases like cryptocurrency) makes these
            technologies difficult to oversee.
          </h4>
          <h4 className="small-text mt-4">
            <b>3. Difficult to implement :</b> Smart contracts are also
            complicated to implement because it’s still a relatively new concept
            and research is still going on to understand the smart contract and
            its implications fully.
          </h4>

          <h4 className="small-text mt-4">
            <b>In conclusion , </b>
            Smart contracts have the potential to revolutionize the way we
            conduct business by offering a more trustworthy, transparent, and
            efficient way of executing agreements. As blockchain technology
            continues to develop and mature, we can expect to see an increasing
            number of use cases for smart contracts, and their widespread
            adoption could have a transformative impact on the global economy.
          </h4>
        </div>
      </div>
    </>
  );
};

export default Post8;
