import { useEffect } from "react";
import { Img_LibraBlog } from "../../config/constants";
import ReactGA from "react-ga";

const Post3 = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname, "", "Facebook’s Libra Blog");
  }, []);

  return (
    <div className="blogs-container">
      <div className="blog-img-container mt-4">
        <img className="blg-img" src={Img_LibraBlog} alt="Img_LibraBlog" />
      </div>
      <div className="blog-contents txt-blue mb-5">
        <h1 className="title my-4">
          How Will Facebook’s Libra “Blockchain” Really Work ?
        </h1>
        <h4 className="small-text">
          The Libra protocol allows a set of replicas - referred to as
          validators - from different authorities to jointly maintain a database
          of programmable resources. There’s no mincing of words here - the
          system will be controlled by a set of authorities in a top-down
          fashion. However, note that it says the database is for “programmable
          resources” rather than just digital currency. <br />
          <br />
        </h4>
        <h3 className="subtitle my-3">Introduction</h3>
        <h4 className="small-text">
          This ecosystem will offer a new global currency—the Libra coin—which
          will be fully backed with a basket of bank deposits and treasuries
          from high-quality central banks. Libra is a generic crypto asset
          protocol, and the first asset will be a stablecoin. Over time,
          membership eligibility will shift to become completely open and based
          only on the member’s holdings of Libra. <br />
          <br />
          Sounds an awful lot like proof of stake. Apparently, the plan is to
          open up membership after five years, and hopefully they’ll have
          figured out proof of stake by then—though I expect they’ll run into
          the same problems as Ethereum. The association has published reports
          outlining the roadmap for the shift toward a permissionless system.
          <br />
          <br />
          I’m pretty sure this would be the first time a distributed network
          transitioned from permissioned to permissionless. Perhaps the network
          as a whole can switch to proof of stake, but in order for the
          stablecoin peg/basket to be maintained, some set of entities must keep
          a bridge open to the traditional financial system. This will be a
          persistent point of centralized control via the Libra Association.
          <br />
          <br />
        </h4>
        <h3 className="subtitle my-3">Logical Data Model</h3>
        <h4 className="small-text">
          The Libra protocol uses an account-based data model to encode the
          ledger state. From a data structure perspective, Libra is more like
          Ethereum or Ripple than Bitcoin. A UTXO model has pros and cons—such
          as better privacy and a more robust transaction history due to the
          simplicity of the output-based history—but it can be harder to work
          with complex smart contracts. <br />
          <br />
          Thus the account model makes sense because Facebook is unlikely to be
          concerned with privacy, even though the platform sounds interested in
          smart contracts. The Libra protocol does not link accounts to a
          real-world identity. A user is free to create multiple accounts by
          generating multiple key-pairs. Accounts controlled by the same user
          have no inherent link to each other. This scheme follows the example
          of Bitcoin and Ethereum in that it provides pseudonymity for users.
          <br />
          <br />
          This sounds surprisingly good, but I wonder if this is also the case
          for Libra Coin, the asset. It will be interesting to observe how open
          this system is for developers who want to build apps that more heavily
          preserve privacy. Every resource has a type declared by a module.
          Resource types are nominal types that consist of the name of the type
          and the name and address of the resource’s declaring module. <br />
          <br />
          Alphapoint, Polymath, Smart Varlor and Harbor are working on platforms
          for asset tokenization.
        </h4>
        <h3 className="subtitle my-3">Executing Transactions</h3>
        <h4 className="small-text">
          Many parts of the core logic of the blockchain are defined using Move,
          including the deduction of gas fees. To avoid circularity, the VM
          disables the metering of gas during the execution of these core
          components. This sounds pretty dangerous, but the document’s authors
          note that the core components must be written defensively to prevent
          DoS attacks. <br />
          <br />
          The key feature of Move is the ability to define custom resource types
          … the Move type system provides special safety guarantees for
          resources. A resource can never be copied, only moved. These
          guarantees are enforced statically by the Move VM. This allows us to
          represent Libra coins as a resource type in the Move language. That
          clears up the earlier question of whether or not Libra coins are a
          native asset like ETH or BTC. <br />
          <br />
          I expect these coins are just the default or only resource type that
          will be allowed in the system when it launches, and other resources
          will come later. Move’s stack-based bytecode has fewer instructions
          than a higher-level source language would. In addition, each
          instruction has simple semantics that can be expressed via an even
          smaller number of atomic steps. This reduces the specification
          footprint of the Libra protocol and makes it easier to spot
          implementation mistakes. <br />
          <br />
        </h4>
        <h3 className="subtitle my-3">
          Authenticated Data Structures and Storage
        </h3>
        <h4 className="small-text">
          The Libra protocol uses a single Merkle tree to provide an
          authenticated data structure for the ledger history … specifically,
          the ledger history uses the Merkle tree accumulator approach to form
          Merkle trees, which also provides efficient append operations. Once
          again we see that “The Libra Blockchain” is not actually a blockchain.
          It’s really weird that this protocol seems to be very well designed,
          and yet they keep calling it a blockchain when the data structure of
          the ledger history is a set of signed ledger states. <br />
          <br />
          Validators are making commitments for each ledger state, and all of
          the historical ledger states are also committed to in Merkle trees,
          but I have yet to actually see any backlinked lists of data that form
          a chain—much less a chain of blocks. The authenticator of an account
          is the hash of this serialized representation. Note that this
          representation requires recomputing the authenticator over the full
          account after any modification to the account. <br />
          <br />
          The cost of this operation is O(n), where n is the length of the byte
          representation of the full account. Hmmm, sounds like an opening to
          DoS attacks if there aren’t limitations placed on the amount of data
          stored by a given account. We anticipate that as the system is used,
          eventually storage growth associated with accounts may become a
          problem. Just as gas encourages responsible use of computation
          resources, we expect that a similar rent-based mechanism may be needed
          for storage. We are assessing a wide range of approaches for a
          rent-based mechanism that best suits the ecosystem. Another unsolved
          problem. <br />
          <br />
          Can’t wait for “The rent is too damn high!” memes. The voting power
          must remain honest both during the epoch as well as for a period of
          time after the epoch in order to allow clients to synchronize to the
          new configuration. A client that is offline for longer than this
          period needs to resynchronize using some external source of truth to
          acquire a checkpoint that they trust. <br />
          <br />
        </h4>
        <h3 className="subtitle my-3">Networking</h3>
        <h4 className="small-text">
          Each validator in the Libra protocol maintains a full membership view
          of the system and connects directly to any validator it needs to
          communicate with. A validator that cannot be connected to directly is
          assumed to fall within the quota of Byzantine faults tolerated by the
          system. <br />
          <br />
          This is going to require a lot of work in order to scale the system
          past several hundred validators. <br />
          <br />
        </h4>
        <h3 className="subtitle my-3">Performance</h3>
        <h4 className="small-text">
          We anticipate the initial launch of Libra protocol to support 1,000
          payment transactions per second with a 10-second finality time between
          a transaction being submitted and committed. <br />
          <br />
          Since there will only be 100 or so validators, and they’re all
          directly connected to each other, 10-second block times sound
          doable.There were some references earlier to maintaining the ability
          for a validator to perform an initial sync from scratch, rather than
          trusting signed states from other validators. <br />
          <br />
          I expect that if Libra gets much use at all, it will quickly become
          highly impractical to perform such a sync, and as such, the node
          security model will be highly reliant upon trusting the validators.
          <br />
          <br />
        </h4>
        <h3 className="subtitle my-3">
          Implementing Libra Ecosystem Policies with Move
        </h3>
        <h4 className="small-text">
          The [Libra coin] reserve is the key mechanism for achieving value
          preservation. Through the reserve, each coin is fully backed with a
          set of stable and liquid assets. The Libra coin contract allows the
          association to mint new coins when demand increases and destroy them
          when the demand contracts. The association does not set a monetary
          policy. It can only mint and burn coins in response to demand from
          authorized resellers. <br />
          <br />
          Users do not need to worry about the association introducing inflation
          into the system or debasing the currency: For new coins to be minted,
          there must be a commensurate fiat deposit in the reserve. Okay, but
          now we’re talking about events that are external to the network. As
          stated earlier in the white paper, the network is not able to execute
          scripts that use inputs of data that are external to the network’s
          state. <br />
          <br />
          Thus the modifiers “can” and “must” in the above snippet are surely
          referring to Libra Association policies or contractual obligations of
          which the network is unaware. The consensus algorithm relies on the
          validator-set management Move module to maintain the current set of
          validators and manage the allocation of votes among the validators.
          Initially, the Libra Blockchain only grants votes to Founding Members.
          Assuming that validators vote on changes to the validator set, it
          sounds like this results in a similar problem to what we see in proof
          of stake systems—long range attacks. <br />
          <br />
          If a sufficient threshold of founding members’ private keys are
          compromised, could an attacker write a new ledger history from
          genesis? If so, would other nodes accept it? It’s unclear if the
          consensus protocol allows for rewriting old states or if it’s
          append-only. <br />
          <br />
        </h4>
      </div>
    </div>
  );
};

export default Post3;
