import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Img_Sandip,
  Img_Ronak,
  Arrow_Right,
  Arrow_Left,
  Img_Linkedin,
} from "../config/constants";
import AOS from "aos";

export const OurTeam = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const CarouselData = [
    {
      name: "Ronak P.",
      designation: "CEO & Founder",
      img: Img_Ronak,
      isActive: true,
      url: "https://in.linkedin.com/in/ronak-patel-4b4ab742",
      information:
        "Creative and determined, a strategic deal maker with strong leadership and influencing skills demonstrated in various Senior Leadership Roles across some of the most innovative Software companies.",
    },
    {
      name: "Sandip P.",
      designation: "CTO & Founder",
      img: Img_Sandip,
      isActive: false,
      url: "https://in.linkedin.com/in/sandip-patel-b2709143",
      information:
        "I help organizations on design, thinking, and evaluating Blockchain solutions from Multiple Dimensions, Rapid Prototyping, Proof of Concepts, Pilot Development to Complete Implementation and deployment.",
    },
  ];

  return (
    <div className="team-container">
      <div className="team-cover">
        <div className="team-text" data-aos="fade">
          <h3 className="title txt-blue mb-4">
            Our team is very expert to{" "}
            <span className="txt-cyan"> help 💪 </span>
          </h3>
          <h4 className="small-text txt-gray">
            Our founding team brings in 10+ years of experience in enterprise IT
            architecture, Blockchain, big data, artificial intelligence,
            integration and solution delivery in telecommunications, Health
            Care, and financial services.
          </h4>
        </div>
        <div
          id="carouselExampleControls"
          className="carousel slide carous"
          data-bs-ride="carousel"
          data-aos="fade"
        >
          <div className="carousel-inner">
            {CarouselData.map(
              ({ name, designation, information, img, isActive, url }, i) => (
                <div
                  className={`carousel-item ${isActive ? "active" : ""}`}
                  key={i}
                >
                  <div className="carousel-container">
                    <div className="carousel-img">
                      <img src={img} alt={name} />
                    </div>
                    <div className="carousel-details">
                      <h3 className="txt-blue name mb-2">{name}</h3>
                      <h3 className="txt-gray mb-2">{designation}</h3>
                      <h4 className="small-tetx mb-3"> {information} </h4>
                      <Link to={url} target="_blank" rel="noreferrer">
                        <img src={Img_Linkedin} alt="Img_Linkedin" />
                      </Link>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="carousel-btn" data-aos="fade">
            <button
              className="cbtn-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <img src={Arrow_Left} alt="Arrow_Left" />
            </button>
            <button
              className="cbtn-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <img src={Arrow_Right} alt="Arrow_Right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
