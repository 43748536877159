import { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  Pink_Arrow_Right,
  Pink_Arrow_Left,
  Img_Adam,
  Img_Vasudha,
  Img_Rhyan,
  Img_Cristian,
  Img_Orbitium,
  Img_MoonMonkey,
  Img_Michael_Calce,
  Img_Ben_Dudley,
} from "../../config/constants";
import { ReactComponent as Rating } from "../../assets/images/star-rating.svg";
import AOS from "aos";

const Testimonial = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const TestimonialData = [
    {
      name: "Michael Calce",
      designation: "Chief Executive Officer at DecentraWeb",
      img: Img_Michael_Calce,
      headText:
        "Knackroot was an exceptional developer who consistently delivered high-quality code.",
      review:
        "I have been in tech for over 25 years and have worked on many startups/exits. The foundation of every good tech project is the developers, Knackroot has exceeded my expectations for the amount of technical work required to complete this project. I will start by saying that when we did our tech audit by Hacken, I was blown away by the almost flawless score we got back. Decentraweb is a very complex project with a multi-faceted use case, Knackroot did a great job at developing a seamless experience.",
    },
    {
      name: "Adam Barlam",
      designation: "Chain Games , Decentraweb & Trustswap",
      img: Img_Adam,
      headText:
        "Pleasure Working with Knackroot for blockchain related development tasks.",
      review:
        "It was a pleasure to work with Knackroot TechnoLabs for blockchain related development tasks. They have an excellent understanding of core competencies, excelled at communication between team members & organization.",
    },
    {
      name: "Malik B.",
      designation: "Co-Founder at Orbitium Network",
      img: Img_Orbitium,
      headText:
        "Dedicated blockchain developers deliver the perfect end product. Highly recommended.",
      review:
        "I am absolutely thrilled with the exceptional blockchain development services provided by Knackroot.com. Their team has an outstanding level of knowledge and expertise in this cutting-edge technology, making them the best choice for any type of blockchain project.",
    },
    {
      name: "Christiaan van Steenbergen",
      designation: "Founder at Next.Exchange",
      img: Img_Cristian,
      headText:
        "Superb blockchain developers , Very good company for blockchain development",
      review:
        "Knackroot is a very good company for blockchain development. They build for us a complete blockchain environment from testnet to mainnet with masternodes. Top Rated !",
    },
    {
      name: "Mooning Monkey",
      designation: "Founder at Mooning Monkey",
      img: Img_MoonMonkey,
      headText:
        "Highly Skilled Blockchain Developers Deliver High-Quality Services and Deliverables.",
      review:
        "I cannot say enough good things about the exceptional service provided by Knackroot.com. Their team is highly knowledgeable and experienced in blockchain development. They consistently deliver high-quality products and always meet deadlines.",
    },
    {
      name: "Vasudha Bhushan",
      designation: "Project Manager at Aeco Pacific",
      img: Img_Vasudha,
      headText:
        "Good Job , Collaborated well with the team to complete the status.",
      review:
        "Knackroot TechnoLabs LLP understood our requirements well and implemented accordingly. They had excellent technical skills to complete the job. They communicate well on the status, progress and any issues faced.",
    },
    {
      name: "Rhyan Garrison",
      designation: "Director Of Global Operations at 3RE",
      img: Img_Rhyan,
      headText:
        "Hard working, Built a Electum wallet for my coin and I am very satisfied",
      review:
        "I worked the most with Ronak and he was able to communicate with me on a daily basis of the progress of his work, He is good at taking feed back and following up with the needs of the project.",
    },

    {
      name: "Ben Dudley",
      designation: "Managing Director at Zento Group",
      img: Img_Ben_Dudley,
      headText: "Trustworthy and reliable. Achieves desired results",
      review:
        "I have worked with Knackroot since 2017.The team have successfully completed a number of projects that have delivered tools that we use in our business every day.For example, on one project, due to the number of clients we represent and the value of the transactions we process, the requirement to correctly allocate transactions to each client is imperative.Knackroot overhauled our process to enable its completion in 10% of the time it previously required and with 99.9999% accuracy.Based on our ongoing satisfaction with Knackroot, we recommend them for being trustworthy and reliable.",
    },
  ];

  const options = {
    autoWidth: true,
    margin: 15,
    loop: true,
    nav: true,
    navText: [
      `<img className='testimonial-nav-left' src=${Pink_Arrow_Left} alt="Pink_Arrow_Left">`,
      `<img className='testimonial-nav-right' src=${Pink_Arrow_Right} alt="Pink_Arrow_Right">`,
    ],
    dots: false,
  };

  return (
    <div
      className="testimonial-container pb-5"
      id="testimonials"
      data-aos="fade"
    >
      <h3 className="title txt-blue text-center py-5">
        Testimonials <span>&#9996;</span>
      </h3>
      <OwlCarousel
        className="owl-carousel owl-theme"
        id="testimonialSlider"
        {...options}
      >
        {TestimonialData.map(
          ({ name, designation, img, review, headText }, i) => (
            <div className="tm-card-cover" key={i}>
              <div className="tm-content-head">
                <q>{headText}</q>
              </div>
              <div className="review-stars-cover">
                <Rating />
              </div>
              <div className="tm-content txt-gray small-tetx">
                <div className="devider" />
                <div className="tm-handle-overflow">{review}</div>
              </div>
              <div></div>
              <div className="data">
                <div>
                  <img className="ppl-img" src={img} alt="Img_Adam" />
                </div>
                <div className="ppl-data ps-4 pt-2">
                  <h3 className="txt-purple">{name}</h3>
                  <h4 className="txt-gray">{designation}</h4>
                </div>
              </div>
            </div>
          )
        )}
      </OwlCarousel>
    </div>
  );
};

export default Testimonial;
